import React from "react";
import {OverlayTrigger, Popover, Table} from "react-bootstrap";
import {checkBoxQuestion, closedQuestion, openQuestion} from "./ChecklistUtils";

const ChecklistTable = ({useTableRef,
                            tbodyRef,
                            elements,
                            elementActiveStep,
                            isError,
                            error,
                            groupQuestions,
                            sortedQuestions,
                            _questions,
                            parentQuestionValue,
                            answers,
                            handleInputChange
                        }) => {

    return (

    <Table style={{ marginTop: "5px" }} ref={useTableRef}>
        <colgroup>
            {/*<col style={{ width: "5%" }} />*/}
            <col style={{ width: "5%" }} />
            <col style={{ width: "65%" }} />
            <col style={{ width: "25%" }} />
        </colgroup>
        <thead>
        <tr>
            {/*<th className="text-center col-1">#</th>*/}
            <th>Info</th>
            <th>Question</th>
            <th>Answer</th>
        </tr>
        </thead>
        <tbody ref={tbodyRef}>
        {elements.length > 0 ? (
            elementActiveStep ? (
                isError ? (
                    <tr>
                        <td colSpan="2" className="danger">
                            {error}
                        </td>
                    </tr>
                ) : groupQuestions && Object.values(groupQuestions).length > 0 ? (
                    sortedQuestions.flatMap((question, index) => (
                        question.PageTree === 1 ?
                            <React.Fragment key={question.id}>
                                <tr className="question_row">
                                    {/*<td className="text-center col-1 align-items-center">{index}</td>*/}
                                    <td>
                                        <OverlayTrigger placement="bottom"
                                                        trigger={"hover"} overlay={
                                            <Popover
                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                <Popover.Body>{question.QuestionInfo}</Popover.Body>
                                            </Popover>
                                        }>
                                            <i style={{ color: '#0d6efd' }}
                                               className="fas fa-info-circle" />
                                        </OverlayTrigger>
                                    </td>
                                    <td className={"text-wrap"}>
                                                                            <span
                                                                                className="col-12 checklist-question">
                                                                                {question.Name}
                                                                            </span>
                                    </td>
                                    <td className="col-3">
                                        {question.QuestionType === "open"
                                            ? openQuestion(question, answers, handleInputChange)
                                            : question.QuestionType === "multi"
                                                ? closedQuestion(question, answers, handleInputChange)
                                                : question.QuestionType === "check" || question.QuestionType === "checkbox"
                                                    ? checkBoxQuestion(question, answers, handleInputChange)
                                                    : null}
                                    </td>
                                </tr>
                                {(question.SubQuestions && parentQuestionValue[question.QuestionID] === "Ja" && question.SubQuestions.map((subQuestionId) => {
                                    let subQuestionIndex = _questions.findIndex(q => q.QuestionID === subQuestionId);
                                    let subQuestion = _questions[subQuestionIndex];
                                    if (!subQuestion) return null;
                                    else
                                        return (
                                            <tr className="question_row" key={subQuestion.questionID}>
                                                {/*<td className="text-center col-1 align-items-center">{subQuestionIndex + 1}</td>*/}
                                                <td>
                                                    <OverlayTrigger placement="bottom"
                                                                    trigger={"hover"}
                                                                    overlay={
                                                                        <Popover
                                                                            style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                            <Popover.Body>{subQuestion.QuestionInfo}</Popover.Body>
                                                                        </Popover>
                                                                    }>
                                                        <i style={{ color: '#0d6efd' }}
                                                           className="fas fa-info-circle" />
                                                    </OverlayTrigger>
                                                </td>
                                                <td className={"text-wrap"}>
                                                                                        <span
                                                                                            className="col-12 checklist-question">
                                                                                            {subQuestion.Name}
                                                                                        </span>
                                                </td>
                                                <td className="col-3">
                                                    {subQuestion.QuestionType === "open"
                                                        ? openQuestion(subQuestion, answers, handleInputChange)
                                                        : subQuestion.QuestionType === "multi"
                                                            ? closedQuestion(subQuestion, answers, handleInputChange)
                                                            : subQuestion.QuestionType === "check"
                                                                ? checkBoxQuestion(subQuestion, answers, handleInputChange)
                                                                : null}
                                                </td>
                                            </tr>
                                        )
                                }))}
                            </React.Fragment> : null
                    ))
                ) : (
                    <p className={"text-danger"}>Geen vragen gevonden in deze groep.</p>
                )
            ) : (
                <p className={"text-danger"}>Geen onderdelen gevonden in deze groep.</p>
            )
        ) : (
            <p className={"text-danger"}>Geen groepen gevonden in deze checklist.</p>
        )}
        </tbody>
    </Table>
    )
}

export default ChecklistTable;