import React, { useState } from 'react';
import { Col, Form, Pagination, Row } from 'react-bootstrap';

const PageAllowed = [-2, -1, 0, 1, 2];
const PageEllips = [-3, 3];
const SelectOption = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' },
];

export function usePagination(data) {
    const [currentPage, setCurrentPage] = useState(1);
    const [ItemsPerPage, setItemsPerPage] = useState(50);

    const indexOfLastItem = currentPage * ItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - ItemsPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    const Pages = Math.ceil(data?.length / ItemsPerPage);
    const pageNumbers = [];
    if (Pages > 3) {
        pageNumbers.push(
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />,
            <Pagination.Prev
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
            />,
        );
    }
    for (let i = 1; i <= Pages; i += 1) {
        if (PageAllowed.includes(i - currentPage) && Pages > 1) {
            pageNumbers.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>,
            );
        } else if (PageEllips.includes(i - currentPage)) {
            pageNumbers.push(<Pagination.Ellipsis />);
        }
    }
    if (Pages > 3) {
        pageNumbers.push(
            <Pagination.Next
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === Pages}
            />,
            <Pagination.Last
                onClick={() => setCurrentPage(Pages)}
                disabled={currentPage === Pages}
            />,
        );
    }

    const ChangeItemsPerPage = (e) => {
        setItemsPerPage(e);
        setCurrentPage(1);
    };

    const AboveTable = (
        <Row className="align-items-center">
            <Col md={1} style={{ color: 'black' }}>
                <Form.Select onChange={(e) => ChangeItemsPerPage(e.target.value)} defaultValue={ItemsPerPage}>
                    {SelectOption.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Form.Select>
            </Col>
            <Col md={2}>
                <span className="text-primary" title="Huidige/Aantal, Artikelen">
                    <i className="fas fa-map" />
                    {currentPage}
                    /
                    {Pages}
                    ,
                    <i className="fas fa-align-justify" />
                    {data?.length}
                </span>
            </Col>
        </Row>
    );

    const BelowTable = (
        <Pagination>{pageNumbers}</Pagination>
    );

    const reset = () => {
        setCurrentPage(1);
    };

    return {
        currentItems, AboveTable, BelowTable, indexOfFirstItem, reset, setItemsPerPage
    };
}
