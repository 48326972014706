/**
 * README Checklist
 *
 * CheckListProject.jsx is een React component bestand dat de hoofdcomponent CheckListProject bevat.
 * Deze component wordt gebruikt om verschillende vragenlijsten in de applicatie te beheren.
 *
 * =====================================================================================================================
 * CheckListProject:
 * Deze component wordt gebruikt om een vragenlijst te beheren.
 * Het biedt een gebruikersinterface voor het navigeren door de vragenlijst, het beantwoorden van vragen, en het bijhouden van de voortgang.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het CheckListProject.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige vragen, de functie om de indiening van het formulier te verwerken, de functie om een vraag te verwijderen, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een selectedRow object dat de gegevens van de geselecteerde rij bevat, en een handleSubmitMeta functie die de indiening van het formulier verwerkt.
 *
 */

import { useParams } from "react-router-dom";
import CheckList from "../../../components/Checklist/Checklist";

// Hoofdcomponent van de pagina
export default function CheckListProject() {
    const { id } = useParams();

    return CheckList(id);
}
