export default function SortManager(a, b, columnName, sortDirection) {
    const multiplier = sortDirection === 'asc' ? 1 : -1;
    const aValue = a[columnName] !== undefined ? a[columnName] : a.Metadata && a.Metadata[columnName];
    const bValue = b[columnName] !== undefined ? b[columnName] : b.Metadata && b.Metadata[columnName];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * multiplier;
    } else {
        return (aValue > bValue ? 1 : -1) * multiplier;
    }
}
