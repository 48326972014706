import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Nav, Offcanvas, Row, Tab, Table, Modal } from 'react-bootstrap';
import { usePagination } from '../managers/PaginationManager';
import apiCall from "../utils/apiCall";
import { displayToast } from "../managers/ToastManager";
import { useNavigate } from 'react-router-dom';
import { PropertyBoxCabinet, PropertyBoxDevices, PropertyBoxEquipment } from '../propertyboxes/PropertyBoxes';
import ImportFile, { ImportToDatabase } from '../managers/ImportManager';
import ExportDialogBox from "../managers/ExportManager";
import { displayConfirmAlertCustom } from '../managers/AlertManager';
import { useAuth } from "../../context/AuthContext";
import AddProperty from "../propertyboxes/AddItemToProjectMetadata";
import SortManager from '../managers/SortManager';

/**
 * README ProjectMetaDataTable
 *
 * ProjectMetaDataTable.jsx is een React-componentbestand dat de hoofdcomponent ProjectMetaDataTable bevat.
 * Deze component wordt gebruikt om eigenschappen van verschillende elementen in de applicatie weer te geven en te manipuleren.
 *
 * ============================================================================================================================
 *
 * ProjectMetaDataTable:
 * Deze component wordt gebruikt om de eigenschappen van kasten, apparaten en apparatuur weer te geven en te bewerken.
 * Het biedt een gebruikersinterface voor het bewerken van de eigenschappen van een geselecteerde rij in de respectievelijke lijst.
 * De eigenschappen omvatten de naam van het item, het type en andere gerelateerde eigenschappen.
 * De component maakt gebruik van Bootstrap voor stijl en lay-out.
 *
 * =============================================================================================================================
 *
 * Gebruik: Om deze component te gebruiken, importeer je deze vanuit het ProjectMetaDataTable.jsx-bestand en voeg je deze toe aan je React-component.
 * Je moet de benodigde props voor de component verstrekken.
 * De props omvatten de id van het project en het type van de lijst die moet worden weergegeven.
 *
 * Opmerking: Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je verstrekt overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat de naam van het item, het type en andere gerelateerde eigenschappen.
 */

/**
 *
 * @param id
 * @param {String} type
 *
 * @returns
 */


const ProjectMetaDataTable = ({ id, type }) => {
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddProperty, setShowAddProperty] = useState(false);
    const [newProperty, setNewProperty] = useState(null);
    const [devices, setDevices] = useState();
    const [cabinets, setCabinets] = useState();
    const [key, setKey] = useState('');
    const [fullwidth, setFullwidth] = useState('50vw');
    const [elements, setElements] = useState([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [columnName, setColumnName] = useState(null);
    const [selectedGroupType, setSelectedGroupType] = useState('All');
    const [activeGroup, setActiveGroup] = useState(null);
    const [groupTypes, setGroupTypes] = useState([]);
    const [importedData, setImportedData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const lastRowRef = useRef();
    const navigate = useNavigate();
    const { user } = useAuth();

    // ** Table functions **
    const showColumns =
        type === "data_equipmentlist" ? [
            'Area',
            'Machine',
            'Number',
            'CabinName',
            'Group',
            'Struct',
            'Instance',
            'GroupType'
        ] : type === "data_devicelist" ? [
            "Cabinet",
            'DeviceName',
            'DeviceType',
            'Network',
            'IP-Address'
        ] : type === "data_cabinetlist" ? [
            'Name',
            'Merk',
            'Type kast',
            'Materiaal',
            'Aantal',
            "AfleverAdres",
        ] : []

    //Haalt de data voor de type lijst op
    const getData = () => {
        Promise.all([
            apiCall(`projectlist/get`, 'POST', { Code: id }),
            apiCall(`metadata/columns`, 'POST')
        ])
            .then(([projectlist, metadata]) => {
                // Zet de data van de type lijst in de state
                setData(projectlist[type]);

                // Zet de devices en cabinets in de state
                setDevices(projectlist.data_devicelist);
                setCabinets(projectlist.data_cabinetlist);

                // Namen van de type lijst
                let name_list = {
                    data_equipmentlist: 'Equipment',
                    data_devicelist: 'Devices',
                    data_cabinetlist: 'Cabinets'
                };

                // Haalt de metadata op voor de type lijst
                setColumns(metadata[name_list[type]]);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleDeleteRow = async (index) => {
        setShowOffcanvas(false);
        displayConfirmAlertCustom(
            "Verwijderen",
            <p className="text-danger"> weet je zeker dat je deze regel wilt verwijderen?</p>,
            "confirm",
            [
                async () => {
                    try {
                        await apiCall(`projectlist/delete`, "POST", { "index": index, "Code": id, "Type": type })
                        await getData();

                    } catch (error) {
                        displayToast('Er is iets misgegaan', 'error');
                    }
                    displayToast('Regel is succesvol verwijderd', 'success');
                }, null],
            [
                "Verwijderen",
                "Annuleren"
            ]);
    }

    // ** End Table functions **

    // ** PROPERTIES **
    // Update de properties van de type lijst
    const handleSubmitProperties = async (index) => {
        // Zet de offcanvas uit
        // Update de properties van de type lijst in de database
        //Als selectedRowData niet veranderd is dan doe niks en displaytoast

        if (selectedRowData === selectedRow) {
            displayToast('Geen wijzigingen gedetecteerd', 'info');
            setShowOffcanvas(false);
            return;
        }

        try {
            await apiCall(`projectlist/update`, 'POST', {
                "index": index,
                "Code": id,
                "Type": type,
                "Data": selectedRowData
            });
            displayToast('Properties zijn succesvol geüpdatet', 'success');
        } catch (error) {
            displayToast('Er was een fout bij het updaten van de properties', 'error');
        }
        finally {
            setShowOffcanvas(false);
            getData();
            setSelectedRow(selectedRowData);
        }
    };

    const handleTabChange = (group) => {
        setActiveGroup(group);
    }

    // Voegt een nieuwe property toe aan de type lijst
    const handleAddRow = async () => {
        try {
            if (newProperty) {
                await apiCall(`projectlist/add`, 'POST', { "Code": id, "Type": type, "Data": newProperty });
                console.log(newProperty);
                await getData();
            }
            else {
                displayToast('Er is geen nieuwe property toegevoegd, want de property is leeg', 'error');
            }
        } catch (error) {
            displayToast(`Er was een fout bij het toevoegen van de ${type === 'data_equipmentlist' ? "Equipment" : type === "data_devicelist" ? "Device" : type === "data_cabinetlist" && "Cabinet"}`, 'error');
        }
        // reset de new property
        setNewProperty(null);
    };

    // ** End PROPERTIES **

    // ** UseEffect Hooks **

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (importedData) {
            setOpenModal(true);
        }
    }, [importedData]);

    useEffect(() => {
        if (!showOffcanvas && !dataFetched) {
            getData();
            setDataFetched(true); // Set flag to true after fetching data
        }
        return () => {
            setDataFetched(false); // Reset flag when effect cleans up
        };
    }, [showOffcanvas]);

    // Zet de groepen en elementen in de state
    useEffect(() => {
        if (selectedRow) {
            const newGroups = columns.map(column => column.Metadata.Group);
            const newElements = columns.map(column => column.Metadata.Element);
            setElements([...new Set(newElements)]);
            setGroups([...new Set(newGroups)]);
            setKey('properties');
        }
    }, [selectedRow, columns]);

    //Zet de groep types in de state
    useEffect(() => {
        if (data) {
            type === "data_equipmentlist" ? setGroupTypes([...new Set(data.map(item => item.GroupType))]) :
                type === "data_devicelist" ? setGroupTypes([...new Set(data.map(item => item.DeviceType))]) :
                    type === "data_cabinetlist" && setGroupTypes([...new Set(data.map(item => item['Type kast']))])
        }
    }, [data]);

    //Zet de hash in de url
    useEffect(() => {
        if (key !== '') {
            window.location.hash = key;
        }
    }, [key]);

    useEffect(() => {
        setSortDirection('asc');
    }, [columnName]);

    const {
        currentItems,
        AboveTable,
        BelowTable,
        indexOfFirstItem,
        reset,
        setItemsPerPage
    } = usePagination(type === "data_equipmentlist" ? data?.filter(item => selectedGroupType === "All" ? item : item.GroupType === selectedGroupType) :
        type === "data_devicelist" ? data?.filter(item => selectedGroupType === "All" ? item : item.DeviceType === selectedGroupType) :
            type === "data_cabinetlist" && data?.filter(item => selectedGroupType === "All" ? item : item['Type kast'] === selectedGroupType), 10);

    // ** End UseEffect Hooks **

    let foundColumn;

    useEffect(() => {
        reset();
    }, [activeGroup]);

    return (
        <>
            {importedData &&
                <Modal size='xl' show={openModal} onHide={() => setOpenModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Importeer Lijst</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Wilt u de bestaande lijst vervangen door de geïmporteerde lijst?</p>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    {console.log(importedData)}
                                    {showColumns.map((column, index) => (
                                        <th key={index}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {importedData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.entries(row).map(([key, value], index) => (
                                            showColumns.includes(key) &&
                                            <td key={index}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            ImportToDatabase(importedData, type, id);
                            getData();
                            setOpenModal(false);
                            setImportedData(null);
                        }}>
                            Importeren
                        </Button>
                        <Button variant="secondary" onClick={() => setOpenModal(false)}>
                            Annuleren
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <div>
                {/* Card component */}
                <Card>
                    {/* Card Header */}
                    <Card.Header>
                        <>
                            <div>
                                <Row>
                                    <Col sm={6}>
                                        <h3>{id} - {type === "data_equipmentlist" ? "Equipment" : type === "data_devicelist" ? "Device" : type === "data_cabinetlist" && "Cabinet"} Lijst </h3>
                                    </Col>
                                    <Col sm={6} className='text-end'>
                                        <Button variant={"danger"} onClick={() => key === "properties" ? navigate(-2) : navigate(-1)}><i
                                            className={"fa fa-arrow-left"} /> Terug</Button>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Button disabled={!user.Roles.includes("Projectleider" && "Admin")} className='m-1'
                                    variant='success'
                                    onClick={() => setShowAddProperty(true)}><i
                                        className={"fa fa-plus"} /> Voeg
                                    nieuw {type === 'data_equipmentlist' ? "Equipment" : type === "data_devicelist" ? "Device" : type === "data_cabinetlist" && "Cabinet"} toe</Button>
                                <Button disabled={!user.Roles.includes("Projectleider" && "Admin")} className='m-1'
                                    variant="warning"
                                    onClick={() => ImportFile(setImportedData)}><i
                                        className={"fa fa-upload"} /> Importeer {type === 'data_equipmentlist' ? "Equipment" : type === "data_devicelist" ? "Device" : type === "data_cabinetlist" && "Cabinet"} Lijst</Button>
                                <Button disabled={!user.Roles.includes("Projectleider" && "Admin")} className={'m-1'}
                                    variant={'info'}
                                    onClick={() => ExportDialogBox(data, type, columns)}><i
                                        className={"fa fa-download"} /> Exporteer {type === 'data_equipmentlist' ? "Equipment" : type === "data_devicelist" ? "Device" : type === "data_cabinetlist" && "Cabinet"} Lijst</Button>
                            </div>
                        </>
                    </Card.Header>
                    {/* Card Body */}
                    <Card.Body>
                        <div className="mb-1">
                            <div className='d-flex justify-content-between mb-2'>
                                <div>
                                    <Tab.Container id="groupType-tabs" activeKey={selectedGroupType}
                                        onSelect={(k) => setSelectedGroupType(k)}>
                                        <Nav variant="pills">
                                            <Nav.Item key={"all"}>
                                                <Nav.Link eventKey={"All"}>All</Nav.Link>
                                            </Nav.Item>
                                            {groupTypes.map((groupType, index) => (
                                                <Nav.Item key={index}>
                                                    <Nav.Link eventKey={groupType}>{groupType}</Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </Tab.Container>
                                </div>
                                <div>
                                    <InputGroup>
                                        <InputGroup.Text>Zoeken</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            style={{ maxWidth: '10vw' }}
                                            name="SearchString"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            {AboveTable}
                            {/* Table component */}
                            <div style={{ height: '67vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                <Table striped hover className='mt-2'>
                                    <colgroup>
                                        {type === "data_equipmentlist" ? (
                                            <>
                                                <col span="1" style={{ width: "2.5%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                                <col span="1" style={{ width: "2.5%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "7.5%" }} />
                                            </>
                                        ) : type === "data_devicelist" ? (
                                            <>
                                                <col span="1" style={{ width: "2.5%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                            </>
                                        ) : type === "data_cabinetlist" ? (
                                            <>
                                                <col span="1" style={{ width: "2.5%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "10%" }} />
                                                <col span="1" style={{ width: "15%" }} />
                                                <col span="1" style={{ width: "2.5%" }} />
                                                <col span="1" style={{ width: "55%" }} />
                                            </>
                                        ) : null

                                        }
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#</th>
                                            {showColumns.map(column => {
                                                // Find the column object in the columns array that matches the current column name
                                                const columnData = columns.find(item => item.Name === column);
                                                // Extract the InformationText from the Metadata of the found column object
                                                const informationText = columnData ? columnData.Metadata.InformationText : '';

                                                return (
                                                    <th
                                                        key={column}
                                                        title={informationText}
                                                        onClick={() => { setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); setColumnName(column) }}
                                                    >
                                                        {column} {column === columnName ? <i key={column} className={`fa fa-sort-${sortDirection}`} /> : <i key={column} className='fa fa-sort' />}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems?.length === 0 ?
                                            <tr>
                                                <td colSpan={showColumns.length + 1} className='text-center text-danger'>
                                                    Geen data gevonden
                                                </td>
                                            </tr>
                                            :
                                            currentItems?.filter(item =>
                                                Object.values(item).some(val =>
                                                    val !== null && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
                                                )
                                            ).sort((a, b) => SortManager(a, b, columnName, sortDirection))
                                                .map((rowData, index) => (
                                                    <tr ref={index === currentItems?.length - 1 ? lastRowRef : null} key={index}
                                                        onClick={() => {
                                                            setSelectedRow(rowData)
                                                            setSelectedRowIndex(data?.indexOf(rowData))
                                                            setShowOffcanvas(true)
                                                            setSelectedRowData(rowData)
                                                            setKey('properties')
                                                        }}>
                                                        <td className='text-center'>{indexOfFirstItem + index + 1}</td>
                                                        {showColumns.map((column) => (
                                                            foundColumn = columns?.find(col => col.Name === column),
                                                            <td key={column}>
                                                                {foundColumn ?
                                                                    foundColumn.Metadata.Type === "checkbox" ? (
                                                                        <Form.Check disabled={true} type="switch"
                                                                            defaultChecked={rowData[column]} />
                                                                    ) : rowData[column]
                                                                    : null}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Card.Body>
                    {/* Card Footer */}
                    < Card.Footer>
                        {BelowTable}
                    </Card.Footer>
                </Card>
                {showAddProperty && <AddProperty
                    columns={columns?.filter(column => showColumns.includes(column.Name)).map(column => column)}
                    devices={devices}
                    cabinets={cabinets}
                    showAddProperty={showAddProperty}
                    setShowAddProperty={setShowAddProperty}
                    type={type}
                    fullwidth={fullwidth}
                    setFullwidth={setFullwidth}
                    newProperty={newProperty}
                    setNewProperty={setNewProperty}
                    handleAddRow={handleAddRow}
                />}

                <Offcanvas show={showOffcanvas} onHide={() => {
                    handleSubmitProperties(selectedRowIndex)
                    setSelectedRow(null)
                }} placement='end' style={{ width: fullwidth }}>
                    <Offcanvas.Header closeButton>
                        <i className="fa fa-arrows-alt fa-2x fullscreen"
                            onClick={() => fullwidth === "50vw" ? setFullwidth("100vw") : setFullwidth("50vw")} />
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        {selectedRow && type === "data_equipmentlist" ?
                            <PropertyBoxEquipment
                                showOffcanvas={showOffcanvas}
                                setShowOffcanvas={setShowOffcanvas}
                                rowElement={selectedRow?.GroupType}
                                cabinets={cabinets}
                                devices={devices}
                                activeGroup={activeGroup}
                                columns={columns}
                                groups={groups}
                                elements={elements}
                                selectedRowData={selectedRowData}
                                selectedRowIndex={selectedRowIndex}
                                setSelectedRowData={setSelectedRowData}
                                handleSubmitProperties={handleSubmitProperties}
                                handleTabChange={handleTabChange}
                                handleDeleteRow={handleDeleteRow}

                            />
                            :
                            type === "data_devicelist" ?
                                <PropertyBoxDevices
                                    setShowOffcanvas={setShowOffcanvas}
                                    columns={columns}
                                    cabinets={cabinets}
                                    selectedRowData={selectedRowData}
                                    setSelectedRowData={setSelectedRowData}
                                    selectedRowIndex={selectedRowIndex}
                                    handleSubmitProperties={handleSubmitProperties}
                                    handleDeleteRow={handleDeleteRow}
                                />
                                :
                                type === "data_cabinetlist" &&
                                <PropertyBoxCabinet
                                    setShowOffcanvas={setShowOffcanvas}
                                    columns={columns}
                                    selectedRowData={selectedRowData}
                                    setSelectedRowData={setSelectedRowData}
                                    selectedRowIndex={selectedRowIndex}
                                    handleSubmitProperties={handleSubmitProperties}
                                    handleDeleteRow={handleDeleteRow}
                                />
                        }
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );

}

export default ProjectMetaDataTable;
