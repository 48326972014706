import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import autoExpand from "../../components/utils/autoExpand";

const Bug = ({props, handleSubmit}) => {
    const FormRef = useRef()
    const [formDataBug, setFormDataBug] = useState({ id: props.EmployeeID, subject: '', reproducibility: '', action: '', result: '', description: '' });
    return (
        <Form ref={FormRef} className='was-validated' onSubmit={() => handleSubmit(formDataBug)}>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Onderwerp</Form.Label>
                        <Form.Control type="text" id="subject" name="subject" onChange={(e) => setFormDataBug({ ...formDataBug, subject: e.target.value })} required />
                    </Form.Group>
                </Col>
                <hr />
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label > Is het probleem reproduceerbaar? </Form.Label>
                        <Form.Select id="reproducibility" name="reproducibility" onChange={(e) => setFormDataBug({ ...formDataBug, reproducibility: e.target.value })} required>
                            <option value="">--- Selecteer een optie ---</option>
                            <option value={true}>Ja</option>
                            <option value={false}>Nee</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Wat heeft u gedaan voordat het probleem optrad?</Form.Label>
                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="action" name="action" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, action: e.target.value })} required />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Wat was het resultaat van deze actie?</Form.Label>
                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="result" name="result" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, result: e.target.value })} required />
                    </Form.Group>
                </Col>
                <hr />
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label> Kunt u het probleem in detail beschrijven? </Form.Label>
                        <textarea className='form-control' placeholder='Voer minimaal 32 tekens in' id="description" name="description" minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataBug({ ...formDataBug, description: e.target.value })} required />
                    </Form.Group>
                </Col>
            </Row>
            <Button type="submit" disabled={!FormRef?.current?.checkValidity()}><i className='fa fa-send' /> Send</Button>
        </Form>
    );
}

export default Bug;