import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Offcanvas, Row, Tab, Table, Tabs } from 'react-bootstrap';
import apiCall from '../utils/apiCall';
import { createDynamicJson } from '../utils/MakeJSON';
import { handleSubQuestionCheckboxChange, nextTab, prevTab } from '../modals/ModalFunctions';
import { useAuth } from "../../context/AuthContext";
import { displayToast } from "../managers/ToastManager";

/**
 * README DetailsQuestionPropertyBox
 *
 * DetailsQuestionPropertyBox.jsx is een React component bestand dat de hoofdcomponent DetailsQuestionPropertyBox bevat.
 * Deze component wordt gebruikt om de details van een specifieke vraag in de applicatie te bekijken en te bewerken.
 *
 * =====================================================================================================================
 *
 * DetailsQuestionPropertyBox:
 * Deze component wordt gebruikt om de details van een specifieke vraag te bekijken en te bewerken.
 * Het biedt een gebruikersinterface voor het bekijken en bewerken van de details van de vraag, zoals het vraagtype, de vraagtekst, het antwoordtype, en andere gerelateerde eigenschappen.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het DetailsQuestionPropertyBox.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de showDetails, setShowDetails, handleClose, questions, setQuestions, currentItems, selectedID, editing, setEditing, taskGroups, questionElements, type, group, code, dataTargets, dataTypes en GetData.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een lijst van vragen, elk met een unieke ID en andere gerelateerde eigenschappen.
 */


/**
 * @param showDetails
 * @param setShowDetails
 * @param handleClose
 * @param questions
 * @param setQuestions
 * @param currentItems
 * @param selectedID
 * @param editing
 * @param setEditing
 * @param taskGroups
 * @param questionElements
 * @param type
 * @param group
 * @param code
 * @param dataTargets
 * @param dataTypes
 * @param GetData
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const DetailsQuestionPropertyBox = ({
    showDetails,
    setShowDetails,
    handleClose,
    questions,
    currentItems,
    selectedID,
    editing,
    setEditing,
    taskGroups,
    questionElements,
    type,
    group,
    code,
    dataTargets,
    dataTypes,
    GetData,
}) => {
    const [key, setKey] = useState('general');
    const [editQuestion, setEditQuestion] = useState({});
    const [UserRoles, setUserRoles] = useState([]);
    const question = currentItems?.find((question) => question.QuestionID === selectedID);
    const { user } = useAuth();

    useEffect(() => {
        (async () => {
            setUserRoles(await apiCall("metadata/questionroles", "POST", {}))
        })();
        //editquestion does not have any objects in them add object questionAnswerOptions with the value of question.QuestionAnswerOptions
        if (question && !editQuestion.QuestionAnswerOptions) {
            setEditQuestion({
                ...question,
                QuestionAnswerOptions: question.QuestionAnswerOptions
            });
        }
    }, []);

    // Verwijderd de vraag
    // const handleDeleteQuestion = async (questionId) => {
    //     // Sluit de details offcanvas
    //     handleClose("details");

    //     // Toont een confirmatie alert om te vragen of de gebruiker zeker weet dat hij de vraag wilt verwijderen
    //     confirmAlert({
    //         title: 'Vraag verwijderen',
    //         message: <b className={"text-danger"}> Weet u zeker dat u deze vraag wilt verwijderen?</b>,
    //         buttons: [
    //             {
    //                 label: 'Ja',
    //                 onClick: async () => {
    //                     try {
    //                         if (type === "global") {
    //                             await apiCall("question/" + group.replace(" ", "").toLowerCase() + "/delete", "POST", { ID: questionId });
    //                         } else if (type === "checklist") {
    //                             await apiCall("checklist/customquestiondelete/" + group.replace(" ", "").toLowerCase(), "POST", {
    //                                 ID: questionId,
    //                                 ChecklistCode: code
    //                             }, {}, true);
    //                         }
    //                         // Verwijderd de vraag uit de lijst en herlaad de data
    //                         // setQuestions(questions.filter((question) => question.QuestionID !== questionId));
    //                         GetData();
    //                         // Toont een toast met de melding dat de vraag is verwijderd
    //                         displayToast("Vraag is verwijderd", "success");
    //                     } catch (error) {
    //                         console.error("Er is een probleem met het verwijderen van de vraag\n" + error)
    //                     }
    //                 }
    //             },
    //             {
    //                 label: 'No',
    //                 onClick: () => {
    //                 }
    //             }
    //         ]
    //     });
    // };

    //Annuleert de wijzigingen
    const cancelChanges = () => {
        handleClose("details");
    }

    // Slaat de vraag op met de nieuwe gegevens
    const saveQuestion = (editQuestion) => {
        (async () => {
            // Zet de editing mode uit
            setEditing(false);
            try {
                // Maak een JSON object van de nieuwe vraag
                const json = createDynamicJson(editQuestion);

                // Kijkt of de vraag een checklist vraag is of een globale vraag
                if (type === "checklist") {
                    await apiCall("checklist/customquestionupdate/" + group.replaceAll(" ", "").toLowerCase(), "POST", {
                        QuestionUpdate: json,
                        ChecklistCode: code
                    }, {}, true);
                } else {
                    await apiCall("question/" + group.replaceAll(" ", "").toLowerCase() + "/update", "POST", json);
                }
                // Sluit de details offcanvas en herlaad de datap
                setShowDetails(false);
                GetData();
                // Toont een toast met de melding dat de vraag is bijgewerkt
                displayToast("Vraag is bijgewerkt!", "success");
            } catch (error) {
                console.error("Er is een probleem met het updaten van de vraag\n" + error)
            }
        })();
    };

    if (question)
        return (
            <Offcanvas className={"w-50"} placement={"end"} show={showDetails} onHide={() => editQuestion ? saveQuestion(editQuestion) : cancelChanges()}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Vraag {question.QuestionID} - Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)} defaultActiveKey="general"
                        id="details-tabs">
                        <Tab eventKey="general" title="Generiek"><br />
                            <Row>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Vraag ID:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                key={question.QuestionID}
                                                type="text"
                                                id='QuestionID'
                                                defaultValue={question.QuestionID}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Onderdeel:
                                        </Form.Label>
                                        <Col md={8}>
                                            {!editing ? (
                                                <Form.Control
                                                    as="input"
                                                    key={`question_element_${question.QuestionID}`}
                                                    id={`question_element_${question.QuestionID}`}
                                                    defaultValue={
                                                        editQuestion && editQuestion.Element != null
                                                            ? editQuestion.Element
                                                            : question.Element
                                                    }
                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                />
                                            ) : (
                                                <>
                                                    <Form.Control
                                                        list={"elements"}
                                                        key={`question_element_${question.QuestionID}`}
                                                        disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                        id={`question_element_${question.QuestionID}`}
                                                        defaultValue={
                                                            editQuestion && editQuestion.Element != null
                                                                ? editQuestion.Element
                                                                : question.Element
                                                        }
                                                        onBlur={(e) =>
                                                            setEditQuestion({
                                                                ...editQuestion,
                                                                Element: e.target.value
                                                            })
                                                        }
                                                    />
                                                    <datalist id="elements">
                                                        {questionElements.map((element) => (
                                                            <option key={element}>{element}</option>
                                                        ))}
                                                    </datalist>
                                                </>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Invullen door:
                                        </Form.Label>
                                        <Col md={8}>
                                            {!editing ? (
                                                <Form.Control
                                                    as="input"
                                                    id={`question_role_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.Role != null
                                                            ? editQuestion.Role
                                                            : question.Role
                                                    }
                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                />
                                            ) : (
                                                <Form.Select
                                                    disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                    as="select"
                                                    id={`question_role_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.Role != null
                                                            ? editQuestion.Role
                                                            : question.Role
                                                    }
                                                    onChange={(e) =>
                                                        setEditQuestion({
                                                            ...editQuestion,
                                                            Role: e.target.value
                                                        })
                                                    }
                                                >
                                                    <option id={`question_role_answer_${question.QuestionID}`}
                                                        key="default">--- Select een Rol ---
                                                    </option>
                                                    {UserRoles.map((role) => (
                                                        <option key={role.QuestionID}>{role}</option>
                                                    ))}
                                                </Form.Select>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Vraag Type:
                                        </Form.Label>
                                        <Col md={8}>
                                            {!editing ? (
                                                <Form.Control
                                                    as="input"
                                                    id={`question_type_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.QuestionType != null
                                                            ? editQuestion.QuestionType
                                                            : question.QuestionType
                                                    }
                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                />
                                            ) : (
                                                <Form.Select
                                                    as="select"
                                                    disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                    id={`question_type_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.QuestionType != null
                                                            ? editQuestion.QuestionType
                                                            : question.QuestionType
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.value === "multi") {
                                                            setEditQuestion({
                                                                ...editQuestion,
                                                                QuestionType: e.target.value,
                                                                QuestionAnswerOptions: []
                                                            })
                                                        }
                                                        else {
                                                            setEditQuestion({
                                                                ...editQuestion,
                                                                QuestionType: e.target.value
                                                            })
                                                        }
                                                    }
                                                    }
                                                >
                                                    <option key="type">--- Selecteer een Type ---</option>
                                                    <option key={"open"}>open</option>
                                                    <option key={"multi"}>multi</option>
                                                    <option key={"checkbox"}>checkbox</option>
                                                </Form.Select>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Vraag:
                                        </Form.Label>
                                        <Col md={8}>
                                            {!editing ? (
                                                <Form.Control
                                                    type="input"
                                                    id={`question_question_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.Name != null
                                                            ? editQuestion.Name
                                                            : question.Name
                                                    }
                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                />
                                            ) : (
                                                <Form.Control
                                                    disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                    type="input"
                                                    id={`question_question_${question.QuestionID}`}
                                                    value={
                                                        editQuestion && editQuestion.Name != null
                                                            ? editQuestion.Name
                                                            : question.Name
                                                    }
                                                    onChange={(e) =>
                                                        setEditQuestion({
                                                            ...editQuestion,
                                                            Name: e.target.value

                                                        })
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                {editQuestion.QuestionType === "multi" || question.QuestionType === "multi" ? (
                                    <Col md={12}>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column>
                                                Meerkeuzen Antwoorden:
                                            </Form.Label>
                                            <Col md={8}>
                                                {editQuestion.QuestionAnswerOptions && editQuestion.QuestionAnswerOptions.map((answer, index) => (
                                                    <div key={index} className={"d-flex mb-1"}>
                                                        <Form.Control
                                                            type="text"
                                                            className={"w-50"}
                                                            defaultValue={answer}
                                                            onChange={(e) => {
                                                                let questionAnswerOptions = [...editQuestion.QuestionAnswerOptions];
                                                                questionAnswerOptions[index] = e.target.value;
                                                                setEditQuestion({
                                                                    ...editQuestion,
                                                                    QuestionAnswerOptions: questionAnswerOptions
                                                                });
                                                            }}
                                                        />
                                                        <Button variant={"outline-danger"}
                                                            onClick={() => {
                                                                const newAnswers = [...editQuestion.QuestionAnswerOptions];
                                                                newAnswers.splice(index, 1);
                                                                setEditQuestion({
                                                                    ...editQuestion,
                                                                    QuestionAnswerOptions: newAnswers
                                                                });

                                                            }}>
                                                            <i className={"fa fa-trash"} />
                                                        </Button>
                                                    </div>
                                                ))}
                                                <Button
                                                    onClick={() => {
                                                        let questionAnswerOptions = Array.isArray(editQuestion.QuestionAnswerOptions) ? editQuestion.QuestionAnswerOptions : [];
                                                        questionAnswerOptions.push("");
                                                        setEditQuestion({
                                                            ...editQuestion,
                                                            QuestionAnswerOptions: questionAnswerOptions
                                                        });
                                                    }}
                                                >
                                                    <i className={"fa fa-plus"} /> Add Answer
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                ) : null}
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Standaard Antwoord:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                type="text"
                                                id='DefaultAnswer'
                                                defaultValue={question.Answer}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) => {
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        Answer: e.target.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                < Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Aangepaste Document:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                type="text"
                                                id='DocumentCustom'
                                                defaultValue={question.DocCustom}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        DocCustom: e.target.value
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Document ID:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                type="text"
                                                id='DocumentID'
                                                defaultValue={question.DocID}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        DocID: e.target.value
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Vraag Informatie:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                type="text"
                                                id='QuestionInfo'
                                                defaultValue={question.QuestionInfo}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        QuestionInfo: e.target.value
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Taakgroep:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Select
                                                id='Taskgroup'
                                                key={question.Taskgroup}
                                                defaultValue={question.Taskgroup}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        Taskgroup: e.target.value
                                                    })
                                                }
                                            >
                                                <option key="default">--- Selecteer een Taskgroup ---</option>
                                                {taskGroups ? Object.entries(taskGroups).map(([id, func]) => (
                                                    <option key={id}>{func.name}</option>
                                                )) : <option key="default">No Taskgroups</option>}

                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Actief:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Check
                                                type="switch"
                                                id='Active'
                                                defaultChecked={question.isActive}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        isActive: e.target.checked
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            PageTree:
                                        </Form.Label>
                                        <Col md={8}>
                                            <Form.Control
                                                type="text"
                                                id='PageTree'
                                                defaultValue={question.PageTree}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) =>
                                                    setEditQuestion({
                                                        ...editQuestion,
                                                        PageTree: e.target.value
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            Subvragen:
                                        </Form.Label>
                                        <Col md={8}>
                                            <div className='subquestionList'>
                                                {questions.filter(item => item.Element === question?.Element && item.QuestionID !== question.QuestionID).map(subquestion => (
                                                    <Form.Check
                                                        type="checkbox"
                                                        key={subquestion.QuestionID}
                                                        disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                        defaultChecked={question.SubQuestions.includes(subquestion.QuestionID) ? true : false}
                                                        className="mt-2"
                                                        id={`question-${subquestion.QuestionID}`}
                                                        label={subquestion.Name}
                                                        onChange={e => handleSubQuestionCheckboxChange(e, subquestion.QuestionID, setEditQuestion)}
                                                    />
                                                ))}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                {question.PageTree === 2 ? (
                                    <Col md={12}>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column>
                                                Hoofdvraag:
                                            </Form.Label>
                                            <Col md={8}>
                                                {questions.filter(item => item.QuestionID === question.ParentQuestionID).map(parentQuestion => (
                                                    <Form.Check
                                                        key={parentQuestion.QuestionID}
                                                        type="checkbox"
                                                        disabled={true}
                                                        defaultChecked={true}
                                                        className="mt-2"
                                                        id={`question-${parentQuestion.QuestionID}`}
                                                        label={parentQuestion.Name}
                                                    />
                                                ))}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                ) : null}
                            </Row>
                        </Tab>
                        <Tab eventKey="references" title="Referenties">
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            Data Types
                                        </Card.Header>
                                        <Card.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Reference</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(question.References).map(([key, value]) => (
                                                        dataTypes?.includes(key) &&
                                                        <tr key={key}>
                                                            <td>{key}</td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    key={key}
                                                                    id={`${key}`}
                                                                    defaultChecked={value}
                                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                                    onChange={(e) =>
                                                                        setEditQuestion({
                                                                            ...editQuestion,
                                                                            References: {
                                                                                ...editQuestion.References,
                                                                                [key]: e.target.checked
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Header>
                                            Data Targets
                                        </Card.Header>
                                        <Card.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Reference</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(question.References).map(([key, value]) => (
                                                        dataTargets?.includes(key) &&
                                                        <tr key={key}>
                                                            <td>{key}</td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    key={key}
                                                                    id={`${key}`}
                                                                    defaultChecked={value}
                                                                    disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                                    onChange={(e) =>
                                                                        setEditQuestion({
                                                                            ...editQuestion,
                                                                            References: {
                                                                                ...editQuestion.References,
                                                                                [key]: e.target.checked
                                                                            }
                                                                        })
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="time" title="Tijd"><br />
                            <Row>
                                {Object.keys(question.Time).map((time) => (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column>
                                            {time.split(/(?=[A-Z])/).join(" ")}:
                                        </Form.Label>
                                        <Col md={6}>
                                            <Form.Control
                                                type={time.includes("Cost") ? "text" : "number"}
                                                min={0}
                                                key={time}
                                                id={`new${time}`}
                                                defaultValue={time.includes("Cost") ? `€${question.Time[time]}` : question.Time[time]}
                                                disabled={!editing || type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                                                onChange={(e) => {
                                                    const sanitizedValue = e.target.value >= 0 ? e.target.value.replace(/[^0-9]/g, '') : 0;
                                                    const numericValue = sanitizedValue === '' ? 0 : parseInt(sanitizedValue, 10);
                                                    if (e.target.value < 0) {
                                                        e.target.value = 0;
                                                        displayToast("Negatieve waarden zijn niet toegestaan", "error");
                                                    }
                                                    else {
                                                        setEditQuestion({
                                                            ...editQuestion,
                                                            Time: {
                                                                ...editQuestion.Time,
                                                                [time]: numericValue
                                                            }
                                                        })
                                                    }
                                                }
                                                }
                                            />
                                        </Col>
                                    </Form.Group>
                                ))}
                            </Row>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                <div className={"text-end"}>
                    <Button variant="secondary" disabled={key === "general"} className={"m-2"}
                        onClick={() => prevTab(key, setKey)}>
                        <i className={"fa fa-arrow-left"} /> Vorige
                    </Button>
                    {!editing ?
                        <Button className={"m-2"} variant="danger" onClick={() => handleClose("details")}>
                            <i className={"fa fa-close"} />Sluiten
                        </Button>
                        : null}
                    {editing ? (<>
                        {/* <Button
                        className={"m-2"}
                        variant="outline-danger"
                        disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                        onClick={() =>
                            handleDeleteQuestion(question.QuestionID)
                        }
                    >
                        <i className={"fa fa-trash"} /> Verwijderen
                    </Button> */}
                        <Button className={"m-2"} variant="danger"
                            onClick={() => cancelChanges()}><i className={"fa fa-cancel"} /> Annuleren</Button>
                    </>
                    ) : null}
                    <Button variant="primary"
                        disabled={type === "global" ? !user.Roles.includes("Admin") : type === "checklist" ? !user.Roles.includes("Admin") && !user.Roles.includes("Projectleider") : true}
                        className={"m-2"}
                        onClick={() => !editing ? setEditing(true) : saveQuestion(editQuestion)}>
                        {editing ? <i className={"fa fa-save"} /> : <i className={"fa fa-pen"} />}
                        {editing ? " Opslaan" : " Bewerken"}
                    </Button>

                    <Button disabled={key === 'time'} className={"m-2"} variant="secondary"
                        onClick={() => nextTab(key, setKey)}>
                        Volgende <i className={"fa fa-arrow-right"} />
                    </Button>
                </div>
            </Offcanvas>
        );
};

export default DetailsQuestionPropertyBox;