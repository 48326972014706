/* eslint-disable no-unused-vars */
import { Button, Card, Col, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getGroupSteps } from "../../components/misc/HardcodedLists.jsx";
import { displayToast } from "../../components/managers/ToastManager.jsx";
import apiCall from "../../components/utils/apiCall.jsx";
import { useNavigate } from "react-router-dom";
import TableQuestions from "../../components/tables/QuestionsTable.jsx";

/**
 * README GlobalQuestions
 *
 *
 * index.jsx is een React component bestand dat de hoofdcomponent GlobalQuestions bevat.
 * Deze component wordt gebruikt om de globale vragen weer te geven en te beheren in de applicatie.
 *
 * =================================================================================================
 *
 * GlobalQuestions:
 * Deze component wordt gebruikt om de globale vragen weer te geven en te beheren.
 * Het biedt een gebruikersinterface voor het navigeren door de vragenlijst en het zoeken naar specifieke vragen.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het index.jsx bestand en voeg het toe aan je React component.
 * Er zijn geen vereiste props voor deze component.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een queries object dat de gegevens van de vragen bevat, en een GetData functie die de ophaling van de vragen verwerkt.
 *
 */

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function GlobalQuestions() {
    const [groupActiveIndex, setGroupActiveIndex] = useState(-1);
    const [group, setGroup] = useState("");
    const [query, setQuery] = useState("");
    const [queries, setQueries] = useState([]);
    const [questionElements, setQuestionElements] = useState([]);

    const navigate = useNavigate();

    //handelt de click van de groepen
    const handleClickGroup = (group, groupIndex) => {
        setGroupActiveIndex(groupIndex);
        setGroup(group);
        setQueries([]);
    };

    //krijgt de data van de API
    useEffect(() => {
        if (group !== "") {
            GetData();
        }
    }, [group]);

    function GetData() {
        (async () => {
            const response = await apiCall(`question/` + group.replace(/ /g, "").toLowerCase(), "POST");
            if (response) {
                setQueries(response.Questions);
                setQuestionElements(response.Elements);

            } else {
                console.error('Antwoord van API is leeg');
            }
        })();
    }

    const groups = getGroupSteps();

    return (
        <>
            <Card className="p-3">
                <Row>
                    <Col md={9}>
                        <h3>Globale Vragen</h3>
                    </Col>
                    <Col md={3} className="text-end">
                        <Button variant="danger" style={{ margin: "4px" }} onClick={() => navigate(-1)}> <i className="fa fa-arrow-left" /> Terug</Button>
                    </Col>
                </Row>
                <hr />
                <Tab.Container id="question-tabs">
                    <Nav className="mb-3">
                        {groups.map((group, groupIndex) => (
                            <Nav.Item key={groupIndex}>
                                <Nav.Link
                                    eventKey={groupIndex.toString()}
                                    className={`link ${groupActiveIndex === groupIndex ? "active" : ""}`}
                                    onClick={() => handleClickGroup(group, groupIndex)}
                                    disabled={groupActiveIndex === groupIndex}
                                    style={{
                                        borderRadius: "10px",
                                    }}
                                >
                                    {group}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
                <Tab.Content>
                    {groupActiveIndex > -1 &&
                        <>
                            <div className="searchbar">
                                <InputGroup>
                                    <InputGroup.Text>Zoeken</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="SearchString"
                                        onChange={(e) => {
                                            groupActiveIndex > -1 && setQuery(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </>
                    }
                    {groupActiveIndex > -1 && getTable(GetData, queries, questionElements, query, groupActiveIndex, groups[groupActiveIndex])}
                </Tab.Content>
            </Card>
        </>
    );

}

// function escapeRegExp(string) {
//     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }

function Search(data, keyword) {
    if (!data) {
        return [];
    }

    // const safeKeyword = escapeRegExp(keyword);
    // const re = new RegExp(safeKeyword, "i");
    const dataArray = Object.values(data);
    return dataArray.filter(entry =>
        Object.values(entry).some(val => typeof val === "string" && val.match(keyword))
    );
}

function getTable(GetData, data, questionElements, query, groupIndex, group) {
    if (!data) {
        displayToast("Er is een fout opgetreden bij het ophalen van de data", "error");
    }
    const filtered = Search(data, query);
    return <TableQuestions GetData={GetData} data={filtered} groupIndex={groupIndex} group={group} questionElements={questionElements} type={"global"} />;
}