/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, Col, Collapse, Form, InputGroup, ListGroup, Modal, ProgressBar, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { displayToast } from "../../../components/managers/ToastManager";
import apiCall from "../../../components/utils/apiCall";
import { ChangeCreateChecklistModal } from "../../../components/modals/ChangeCreateChecklistModal";
import { FaInfoCircle, FaTrash, FaUser } from 'react-icons/fa';
import { FunctionBadge } from "../../../components/managers/BadgeManager";
import { StatusList } from "../../../components/misc/HardcodedLists";
import { useAuth } from "../../../context/AuthContext";
import EmployeeManager from "../../../components/managers/EmployeeManager";
import { OptionsByUserList } from "../../../components/utils/OptionsByFunction";
import ExportButton from "../../../components/utils/ExportButton";

const getProjectById = async (id) => {
    try {
        return await apiCall(`project/getbyid/${id}`, "POST");

    } catch (error) {
        throw new Error(error.message);
    }
};

const bulkByIDs = async (data) => {
    try {
        return await apiCall(`bulk/get/employee`, "POST", { IDs: data });
    } catch (error) {
        throw new Error(error.message);
    }
};

const saveProjectChanges = async (project) => {
    try {
        await apiCall(`project/update`, "POST", project);
        return true;
    } catch (error) {
        throw new Error(error.message);
    }
};

const deleteProject = async (project) => {
    try {
        const res = await apiCall(`project/delete`, "POST", project);
        return !!res;

    } catch (error) {
        throw new Error(error.message);
    }
};

const ProjectDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tempProject, setTempProject] = useState(null);
    const [key, setKey] = useState("general");
    const [subKey, setSubKey] = useState("checklist");
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [openGroups, setOpenGroups] = useState({});
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(new Set());
    const [checklistGroups, setChecklistGroups] = useState([]);
    const [percentages, setPercentages] = useState({});
    const [showChecklistModal, setShowChecklistModal] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const [customerName, setCustomerName] = useState('');
    const [supplyCompanyName, setSupplyCompanyName] = useState('');
    const [endCustomerName, setEndCustomerName] = useState('');
    const [FunctionListAPI, setFunctionListAPI] = useState([]);
    const [projectleiders, setProjectleiders] = useState([]);
    const [projectAssigned, setProjectAssigned] = useState("");
    const [totalTime, setTotalTime] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [checklistVersion, setChecklistVersion] = useState("");
    const [LatestVersion, setLatestVersion] = useState("");

    const sending = false;

    const { user } = useAuth();

    useEffect(() => {
        const fetchProject = async () => {
            try {
                // Haal projectgegevens op met ID
                const projectData = await getProjectById(id);
                setProject(projectData);
                setTempProject(projectData);
                setLoading(false);
                setFunctionListAPI(await apiCall("metadata/functionlist", "POST", {}));
                const projectleidersData = await apiCall("employee/getbyfunction", "POST", "Projectleider");
                setProjectleiders(projectleidersData);
                const hexPattern = /^[a-f0-9]{32}$/;
                if (hexPattern.test(projectData.Manager)) {
                    const manager = await EmployeeManager.create(projectData.Manager);
                    setProjectAssigned(`${manager.employee.Firstname} ${manager.employee.Lastname}`);
                } else {
                    setProjectAssigned("Geen projectleider.");
                }
                // Als projectgegevens en medewerkers bestaan, verwerk medewerker-ID's

                if (projectData && projectData.Employees) {
                    const employeeIds = projectData.Employees.filter(emp => typeof emp === 'string');

                    if (employeeIds.length > 0) {
                        // Haal medewerkergegevens in bulk op met hun ID's
                        const response = await bulkByIDs(employeeIds);
                        setEmployeeData(response);
                        setSelectedEmployees([...selectedEmployees, ...response]);
                    }
                }
            } catch (error) {
                // Handel fouten af bij het ophalen van projectgegevens
                console.error('Ophalen van projectgegevens mislukt:', error);
                setLoading(false);
            }
        };

        // Roep fetchProject aan als ID is gedefinieerd
        if (id) {
            fetchProject();
        }
    }, [id, projectAssigned]);

    // Doet een bulk call om de customer naam op te halen
    useEffect(() => {
        const fetchCompanies = async (ids, setter) => {
            try {
                const response = await apiCall("bulk/get/company", "POST", { IDs: ids });
                if (Array.isArray(response) && response.length > 0) {
                    setter(response[0].Name);
                }
            } catch (error) {
                console.error("Ophalen van company data mislukt:", error);
            }
        };

        if (tempProject) {
            if (tempProject.CustomerID) {
                fetchCompanies([tempProject.CustomerID], setCustomerName);
            }
            if (tempProject.EndCustomerID) {
                fetchCompanies([tempProject.EndCustomerID], setEndCustomerName);
            }
            if (tempProject.SupplyingCompanyID) {
                fetchCompanies([tempProject.SupplyingCompanyID], setSupplyCompanyName);
            }
        }
    }, [tempProject]);

    // Doet een bulk call om de employee data op te halen
    const removeEmployeeFromProject = async (employeesToRemove, Code) => {
        try {
            const employeeIds = employeesToRemove.map(emp => emp._id);

            await apiCall(
                "project/removeemployee", "POST",
                { selectedEmployees: employeeIds, Code }
            );

            const updatedEmployeeData = employeeData.filter(emp => !employeeIds.includes(emp._id));
            setEmployeeData(updatedEmployeeData);
            setSelectedEmployees(updatedEmployeeData);

        } catch (error) {
            throw new Error(error.message);
        }
    };

    useEffect(() => {
        const getEmployees = async () => {
            try {
                const response = await apiCall(
                    "employee/all", "POST", {}
                );
                if (response) setEmployees(response);
            } catch (error) {
                console.error("Error loading employees: ", error);
                setError(error);
            }
        };

        getEmployees();
    }, []);


    const calculatePercentage = (answeredQuestions, totalQuestions) => {
        return totalQuestions.length > 0 ? (answeredQuestions.length / totalQuestions.length) * 100 : 0;
    }

    const getUniqueElements = (groupQuestions) => {
        return groupQuestions.map(question => question.Element)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    const calculateElements = (uniqueElements, groupQuestions) => {
        let elements = {};
        uniqueElements.forEach(element => {
            const elementQuestions = groupQuestions.filter(question => question.Element === element);
            const answeredElementQuestions = elementQuestions.filter(question => question.Answer !== "");
            elements[element] = calculatePercentage(answeredElementQuestions, elementQuestions);
        });
        return elements;
    }

    useEffect(() => {
        (async () => {
            try {
                if (project.HasChecklist) {
                    const response = await apiCall(`checklist/getbycode/${project.Code}`, "POST");
                    if (response.data !== null) {
                        if (checklistGroups.length === 0) {
                            setChecklistGroups(response.Groups);
                            setTotalTime(response.TotalTime);
                            setTotalCost(response.TotalCost);
                            setChecklistVersion(response.Version);
                            setLatestVersion(response.LatestVersion);

                            let result = {};

                            for (const group of response.Groups) {
                                const groupQuestions = response.Questions[group.toLowerCase().replace(/\s/g, '')];

                                // Filter out questions that are not parent questions or have a parent question that is answered with "Ja"
                                const filteredGroupQuestions = Object.values(groupQuestions)
                                    .filter(question => question.Parent === undefined || question.Parent === "Ja");

                                const uniqueElements = getUniqueElements(filteredGroupQuestions);

                                const answeredGroupQuestions = filteredGroupQuestions.filter(question => question.Answer !== "" && question.Answer !== null);
                                const groupPercentage = calculatePercentage(answeredGroupQuestions, filteredGroupQuestions);

                                let elements = calculateElements(uniqueElements, filteredGroupQuestions);

                                result[group] = {
                                    Percentage: groupPercentage,
                                    Elements: elements
                                };
                            }
                            setPercentages(result);
                        }
                    }
                }
            } catch (error) {
            }
        }
        )()

    }, [project]);

    // Om naar edit mode te gaan
    const handleEdit = () => {
        setIsEditing(true);
        displayToast("U bent nu in bewerkingsmodus", "info");
    };

    // Slaat alle nieuwe wijzigingen op
    const handleSave = async () => {
        try {
            const success = await saveProjectChanges(tempProject);
            if (success) {
                setProject(tempProject);
                setIsEditing(false);
                displayToast("Project is succesvol bijgewerkt", "success");
            }
        } catch (error) {
            setError(error);
            displayToast("Project kon niet worden bijgewerkt", "error");
        }
    };

    // Voegt medewerkers toe aan het project
    const handleEmployeeAdd = async () => {
        try {
            const selectedEmployeeIds = selectedEmployees.map(emp => emp._id);
            await apiCall(
                'project/addemployee', 'POST',
                { selectedEmployees: selectedEmployeeIds, Code: project.Code }
            );
            const updatedEmployeeData = [...employeeData, ...selectedEmployees];
            setEmployeeData(updatedEmployeeData);
            setSelectedEmployees([]);
            displayToast("Employees toegevoegd", "success");
            setShowModal(false);
            setKey("employees");
        } catch (error) {
            console.error(error);
            displayToast("Employees konden niet worden toegevoegd", "error");
        }
    };

    // Via deze funjctie ga je uit de edit mode
    const handleCancel = () => {
        setTempProject(project);
        setIsEditing(false);
        displayToast("U bent niet meer in bewerkingsmodus", "info2");
    }

    // Zet de datum naar een ISO string (andere manier van datum tonen)
    const handleChange = (e) => {
        const { id, value } = e.target;
        let updatedValue;
        if (value !== null && value !== undefined && value !== '') {
            updatedValue = (id === 'StartDate' || id === 'EndDate') ? new Date(value).toISOString().slice(0, 10) : value;
        } else {
            updatedValue = '';
        }
        setTempProject(prevProject => ({
            ...prevProject,
            [id]: updatedValue
        }));
    };

    // Verwijderd een medewerker van het project	
    const handleEmployeeDelete = async (employee) => {
        try {
            await removeEmployeeFromProject([employee], project.Code);

            const updatedSelectedEmployees = selectedEmployees.filter((emp) => emp._id !== employee._id);
            setSelectedEmployees(updatedSelectedEmployees);

            const updatedSelectedEmployeeIds = new Set(selectedEmployeeIds);
            updatedSelectedEmployeeIds.delete(employee._id);
            setSelectedEmployeeIds(updatedSelectedEmployeeIds);
            displayToast("Employee verwijderd", "success");
        } catch (error) {
            console.error("Failed to remove employee:", error);
            displayToast(`Kon medewerker niet verwijderen: ${error.message}`, "error");
        }
    };

    // Zet de modal om medewerkers toe te voegen aan een project dicht
    const handleCloseModal = () => {
        localStorage.setItem('modalOpen', 'false');
        setShowModal(false);
        navigate(0);
    };

    // Zet de modal om medewerkers toe te voegen aan een project open
    useEffect(() => {
        const isModalOpen = localStorage.getItem('modalOpen');
        if (isModalOpen === 'true') {
            setShowModal(true);
            localStorage.removeItem('modalOpen');
        }
    }, []);

    // Met deze functie verwijder je een project
    const handleDeleteProject = async () => {
        const confirmDelete = window.confirm("Weet u zeker dat u dit project wilt verwijderen?");
        if (confirmDelete) {
            try {
                const success = await deleteProject(tempProject);
                if (success) {
                    displayToast("Project is succesvol verwijderd", "success");
                    navigate(-1);
                }
            } catch (error) {
                console.error(error);
                displayToast("Project kon niet worden verwijderd", "error");
            }
        } else {
            displayToast("Project is niet verwijderd", "info");
        }
    };

    const handleProjectLeaderUpdate = async (e) => {
        const newProjectLeader = e.target.value;
        try {
            const response = await apiCall("project/update_projectleider", "POST", { Code: project.Code, Manager: newProjectLeader });
            if (response) {
                displayToast(response.message, "success");
                setProjectAssigned(response.Manager);
            }
        } catch (error) {
            console.error(error);
            displayToast("Projectleider kon niet worden bijgewerkt", "error");
        }
    };

    //useEffect functie om naar een andere tab te gaan
    useEffect(() => {
        switch (subKey) {
            case "equipment":
                navigate(`/cabinet/equipmentlist/${project.Code}`);
                break;
            case "device":
                navigate(`/cabinet/devicelist/${project.Code}`);
                break;
            case 'cabinet':
                navigate(`/cabinet/cabinetlist/${project.Code}`)
                break;
            default:
                break;
        }

    }, [subKey]);

    if (loading) {
        return (
            <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                <Spinner />Loading...
            </h1>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Card className='mb-3'>
                <Card.Header>
                    <Row>
                        <Col sm={6}>
                            <h4>{isEditing ? `Project Bewerken - ${project?.Code}` : `Project Details - ${project?.Code}`}</h4>
                        </Col>
                        <Col sm={6} className={"text-end"}>
                            <Button style={{ marginLeft: "5px" }} variant="danger" onClick={() => navigate("/projects")}><i className={"fa fa-arrow-left"} /> Terug</Button>
                        </Col>
                    </Row>
                </Card.Header>
                <Tabs onSelect={setKey} id="controlled-tab-example">
                    <Tab title="Algemene informatie" eventKey="general">
                        <Form>
                            <Row className="p-3">
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Project ID</InputGroup.Text>
                                        <Form.Control type="text" id="id" value={tempProject?._id} disabled />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Code</InputGroup.Text>
                                        <Form.Control type="text" id="Code" value={tempProject?.Code} disabled />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Omschrijving</InputGroup.Text>
                                        <Form.Control type="text" id="Description" value={tempProject?.Description}
                                            disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Start Datum</InputGroup.Text>
                                        <Form.Control type="date" id="StartDate" value={tempProject?.StartDate}
                                            disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Eind Datum</InputGroup.Text>
                                        <Form.Control type="date" id="EndDate" value={tempProject?.EndDate}
                                            disabled={!isEditing} onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Status</InputGroup.Text>
                                        <Form.Control
                                            as="select"
                                            id="Status"
                                            name="Status"
                                            disabled={!isEditing}
                                            value={tempProject.Status}
                                            onChange={handleChange}>
                                            <option value="">Select a function</option>
                                            {StatusList.map((stat, index) => (
                                                <option key={index} value={stat}>
                                                    {stat}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <a className="" href={`/companies/details/${tempProject?.SupplyingCompanyID}`}
                                            style={{ textDecoration: 'none' }}>
                                            <InputGroup.Text className="inputgroup-text">Leverancier</InputGroup.Text>
                                        </a>
                                        <Form.Control type="text" id="Companies" value={supplyCompanyName} disabled
                                            onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <a href={`/companies/details/${tempProject?.CustomerID}`}
                                            style={{ textDecoration: 'none' }}>
                                            <InputGroup.Text className="inputgroup-text">Klant</InputGroup.Text>
                                        </a>
                                        <Form.Control type="text" id="Companies" value={customerName} disabled
                                            onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <a href={`/companies/details/${tempProject?.EndCustomerID}`}
                                            style={{ textDecoration: 'none' }}>
                                            <InputGroup.Text className="inputgroup-text">Eind Klant</InputGroup.Text>
                                        </a>
                                        <Form.Control type="text" id="Companies" value={endCustomerName} disabled
                                            onChange={handleChange} />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text className="inputgroup-text">Project Manager</InputGroup.Text>
                                        <Form.Control
                                            className="form-select"
                                            value={project.Manager}
                                            onChange={handleProjectLeaderUpdate}
                                            as="select"
                                            disabled={!isEditing}
                                        >
                                            <option value="">Ontkoppelen</option>
                                            <OptionsByUserList user_list={projectleiders} />
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab title="Employees" eventKey="employees">
                        <Row className="p-3">
                            <Col md={12}>
                                <Card>
                                    <Card.Header>
                                        <Col>
                                            <h5 style={{ display: 'flex', alignItems: 'center' }}>
                                                Project Members
                                                <Button className="m-2" onClick={() => setShowModal(true)}
                                                    style={{ cursor: 'pointer', fontSize: '1em' }}>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </Button>
                                            </h5>
                                        </Col>
                                    </Card.Header>
                                    <Col md="12">
                                        <div style={{ maxHeight: 'calc(100vh - 400px)' }}>
                                            {employeeData.length === 0 ? (
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '50vh',
                                                    color: '#a1a1a1'
                                                }}>
                                                    <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                    <h6 style={{ marginTop: '8px' }}><em>Nog geen medewerkers
                                                        gekoppeld</em></h6>
                                                </div>
                                            ) : (
                                                <ListGroup id="employees">
                                                    <Row className="p-3">
                                                        {employeeData
                                                            .filter((employee, index, self) =>
                                                                index === self.findIndex((e) => e.Firstname === employee.Firstname && e.Lastname === employee.Lastname)
                                                            )
                                                            .sort((a, b) => a.Function > b.Function ? 1 : -1)
                                                            .map((employee, index) => (
                                                                <Col md={6} key={index}>
                                                                    <ListGroup.Item
                                                                        style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div>
                                                                            {employee.Firstname} {employee.Lastname} <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} />
                                                                        </div>
                                                                        <div style={{ marginLeft: 'auto' }}>
                                                                            <FaUser
                                                                                onClick={() => navigate(`/people/details/${employee._id}`)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '1em',
                                                                                    color: 'gray',
                                                                                    marginRight: '13px'
                                                                                }}
                                                                            />
                                                                            <FaTrash
                                                                                onClick={() => handleEmployeeDelete(employee)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    fontSize: '1em',
                                                                                    color: 'red'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </Col>
                                                            ))}
                                                    </Row>
                                                </ListGroup>
                                            )}
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
                <Card.Footer>
                    <Row>
                        <Col sm={4}>
                            {!isEditing ? (
                                <Button variant="primary" className="me-3" onClick={handleEdit}><i
                                    className={"fa fa-pen"} /> Bewerken</Button>
                            ) : <>
                                <Button variant="primary" className="me-3" onClick={handleSave}> <i
                                    className={"fa fa-save"} /> Opslaan</Button>
                                <Button variant="danger" className="me-3" onClick={handleCancel}> <i
                                    className={"fa fa-cancel"} /> Annuleren</Button>
                            </>
                            }
                            <ExportButton projectId={project?.Code} loading={loading} />
                            {project?.HasChecklist && <Button variant="success" className="ms-3" onClick={async () => {
                                apiCall(`checklist/sync_checklist`, "POST", { ChecklistCode: project.Code }, {}, true)
                                    .then(() => {
                                        navigate(0); // NEEDS TO BE CHANGED TO NOT REFRESH THE PAGE BUT TO UPDATE THE DATA
                                    });
                            }}>
                                <i className={"fa fa-list-alt"} /> Sync Checklist <Badge bg={`${checklistVersion !== LatestVersion ? 'danger' : 'primary'}`} data-toggle="tooltip" title={`Latest version available is: ${LatestVersion}`}>{checklistVersion}</Badge>
                            </Button>}
                        </Col>
                        {key === "general" && isEditing ? (<>
                            <Col className="text-end">
                                <Button hidden={!user.Roles.includes("Admin")} variant="outline-danger" onClick={handleDeleteProject}> <i
                                    className={"fa fa-trash"} /> Project verwijderen</Button>
                            </Col>
                        </>
                        ) : null}
                    </Row>
                </Card.Footer>
            </Card>
            <Card>
                <Card.Body>
                    <Tabs activeKey={subKey} onSelect={setSubKey} id="controlled-tab-example">
                        <Tab title="Checklist" eventKey="checklist">
                            <Card>
                                <Card.Header>
                                    <div>
                                        {subKey === 'checklist' && (
                                            project?.HasChecklist ?
                                                isEditing ?
                                                    <Col>
                                                        <Button variant="success" disabled={!user.Roles.includes("Admin") && !user.Roles.includes("Projectleider")}
                                                            onClick={() => setShowChecklistModal(true)}>
                                                            <i
                                                                className={"fa fa-pen"} /> Checklist bewerken
                                                        </Button>
                                                    </Col>
                                                    :
                                                    <Col>
                                                        <Button variant="success"
                                                            onClick={() => navigate(`/projects/checklist/${id}`)}>
                                                            <i
                                                                className={"fa fa-list-alt"} /> Checklist bekijken
                                                        </Button>
                                                    </Col> :
                                                <Col>
                                                    <Button variant="success" disabled={!user.Roles.includes("Admin") && !user.Roles.includes("Projectleider")}
                                                        onClick={() => setShowChecklistModal(true)}>
                                                        <i
                                                            className={"fa fa-plus"} /> Checklist aanmaken
                                                    </Button>
                                                </Col>
                                        )}
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {project?.HasChecklist && checklistGroups.length > 0 ?
                                        <Row>
                                            {checklistGroups.map((group, index) => (
                                                <Col md={6}>
                                                    <Card key={index}>
                                                        <Card.Header
                                                            onClick={() => setOpenGroups(prev => ({
                                                                ...prev,
                                                                [group]: !prev[group]
                                                            }))}>
                                                            <div>{group}</div>
                                                            <ProgressBar now={Math.ceil(percentages[group].Percentage)}
                                                                label={`${Math.ceil(percentages[group].Percentage)}%`} />
                                                        </Card.Header>
                                                        <Collapse in={openGroups[group]}>
                                                            <Card.Body>
                                                                <ListGroup>
                                                                    {Object.keys(percentages[group].Elements).map((element, index) => (
                                                                        <ListGroup.Item key={index}>
                                                                            <div>{element}</div>
                                                                            <ProgressBar
                                                                                now={Math.ceil(percentages[group].Elements[element])}
                                                                                label={`${Math.ceil(percentages[group].Elements[element])}%`} />
                                                                        </ListGroup.Item>
                                                                    ))}
                                                                </ListGroup>
                                                            </Card.Body>
                                                        </Collapse>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        : <p className="text-danger">This project doesn't have a checklist.</p>}
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab title={"Cabinet lijst"} eventKey={"cabinet"}></Tab>
                        <Tab title={"Device lijst"} eventKey={"device"}></Tab>
                        <Tab title={"Equipment lijst"} eventKey={"equipment"}></Tab>
                    </Tabs>
                    <InputGroup>
                        <Badge>Total Time: {totalTime}</Badge> {" "} <Badge>Total Cost: {totalCost}</Badge>
                    </InputGroup>
                </Card.Body>
            </Card>
            {
                showChecklistModal ? <ChangeCreateChecklistModal
                    show={showChecklistModal}
                    setShow={setShowChecklistModal}
                    handleClose={() => setShowChecklistModal(false)}
                    id={project.Code}
                    hasChecklist={project.HasChecklist}
                /> : null
            }
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Leden toevoegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Lijst van Employees:</h5>
                    <input
                        className="form-control"
                        list="employeeList"
                        type="text"
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                            const selectedEmployee = employees.find(
                                (employee) => `${employee.Firstname} ${employee.Lastname}` === e.target.value
                            );
                            if (selectedEmployee && !selectedEmployeeIds.has(selectedEmployee._id)) {
                                setSelectedEmployees(prevSelectedEmployees => [...prevSelectedEmployees, selectedEmployee]);

                                setSelectedEmployeeIds(new Set(selectedEmployeeIds).add(selectedEmployee._id));
                                setSearchInput("");
                            }
                        }}
                    />
                    <datalist id="employeeList">
                        {employees && employees.length > 0 && employees
                            .filter((employee) =>
                                !selectedEmployeeIds.has(employee._id) &&
                                !selectedEmployees.some(selected => selected._id === employee._id)
                            )
                            .map((employee) => {
                                return (
                                    <option
                                        key={employee._id}
                                        value={`${employee.Firstname} ${employee.Lastname}`}
                                    />
                                );
                            })}
                    </datalist>
                    <div style={{ marginTop: '40px' }}>
                        <h5 style={{ borderBottom: '1px solid gray', paddingBottom: '10px' }}>Aangemelde leden:</h5>
                        {selectedEmployees.sort((a, b) => a.Function > b.Function ? 1 : -1).map((employee, index) => (
                            <div key={index} style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '1.2em',
                                marginBottom: '8px'
                            }}>
                                <span>{employee.Firstname} {employee.Lastname} <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} /></span>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        variant="primary"
                        onClick={handleEmployeeAdd}
                        disabled={sending || selectedEmployees.length === 0}
                        style={{
                            backgroundColor: sending || selectedEmployees.length === 0 ? '#ccc' : '#007bff',
                            color: '#fff',
                            padding: '8px 16px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: sending || selectedEmployees.length === 0 ? 'not-allowed' : 'pointer',
                            marginRight: '8px',
                            fontSize: '1em',
                        }}
                    >
                        {sending ? "Versturen..." : "Verstuur"}
                    </button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Sluiten</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProjectDetails;