import React from 'react';
import { Button, Card, Col, Form, Offcanvas, Row, Tab, Table, Tabs } from 'react-bootstrap';
import apiCall from '../utils/apiCall';
import { getTimeFields } from '../misc/HardcodedLists';
import { capitalize } from '@mui/material';

/**
 * README AddColumnToColumnList
 *
 * AddColumn.jsx is een React component bestand dat de hoofdcomponent AddColumn bevat.
 * Deze component wordt gebruikt om nieuwe kolommen toe te voegen aan een specifieke lijst in de applicatie.
 *
 * =====================================================================================================================
 *
 * AddColumn:
 * Deze component wordt gebruikt om nieuwe kolommen toe te voegen aan een specifieke lijst.
 * Het biedt een gebruikersinterface voor het invoeren van de kolomwaarden en het toevoegen van de nieuwe kolom aan de lijst.
 * De component gebruikt Bootstrap voor styling en layout.
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer het uit het AddColumn.jsx bestand en voeg het toe aan je React component.
 * Je moet de nodige props voor de component leveren.
 * De props omvatten de huidige kolommen, datatypes, datatargets, groepen, elementen, exportnamen, de functie om de toevoeging van de nieuwe kolom te verwerken, en andere gerelateerde gegevens en functies.
 *
 * Opmerking:
 * Deze component is ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je levert overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat een newColumn object dat de gegevens van de nieuwe kolom bevat, en een addColumn functie die de toevoeging van de nieuwe kolom verwerkt.
 *
 */


/**
 *
 * @param show
 * @param type
 * @param close
 * @param newColumn
 * @param setNewColumn
 * @param fullwidth
 * @param setFullwidth
 * @param dataTypes
 * @param dataTargets
 * @param groups
 * @param elements
 * @param exportnames
 * @returns {Element}
 * @constructor
 */
const AddColumn = ({ show, type, close, newColumn, setNewColumn, fullwidth, setFullwidth, dataTypes, dataTargets, groups, elements, exportnames }) => {

    const addColumn = async () => {
        // Add the new column to the columns arrayF
        try {
            // Make API call to create the column
            await apiCall(`metadata/columns_add`, 'POST', { Data: { "Name": newColumn.Name, "Metadata": newColumn.Metadata }, Type: capitalize(type) });
            close();
        } catch (error) {
            console.error('Er is een probleem met het toevoegen van de kolom: ' + error);
        }
    };

    return (
        <Offcanvas show={show} onHide={() => close()} style={{ width: fullwidth }} placement='end'>
            <Offcanvas.Header closeButton>
                {/* add a button to go full screen */}
                <i className="fa fa-arrows-alt fa-2x fullscreen"
                    onClick={() => fullwidth === "50vw" ? setFullwidth("100vw") : setFullwidth("50vw")} />
            </Offcanvas.Header>
            <Offcanvas.Body>
                <h4>Add Column to {capitalize(type)} List</h4>
                <Button className={"me-2"} variant="danger" onClick={() => close()}>
                    <i className="fa fa-close" /> Close
                </Button>
                <Button className={"me-2"} variant="primary" onClick={() => addColumn()}>
                    <i className="fa fa-plus" /> Add Column
                </Button>
                <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="general" title="General">
                        {/* Column properties form fields */}
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Naam:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    type="text"
                                    value={newColumn.Name}
                                    onChange={(e) => setNewColumn({ ...newColumn, Name: e.target.value })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Groep:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    list={"groups"}
                                    type="text"
                                    value={newColumn.Metadata.Group}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, Group: e.target.value }
                                    })}
                                />
                                <datalist id="groups">
                                    {groups.map((group) => (
                                        <option key={group} value={group} />
                                    ))}
                                </datalist>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Onderdeel:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    list={"elements"}
                                    type="text"
                                    value={newColumn.Metadata.Element}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, Element: e.target.value }
                                    })}
                                />
                                <datalist id="elements">
                                    {elements.map((element) => (
                                        <option key={element} value={element} />
                                    ))}
                                </datalist>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Export Name:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    list={exportnames}
                                    type="text"
                                    value={newColumn.Metadata.ExportName}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, ExportName: e.target.value }
                                    })}
                                />
                                <datalist id="exportnames">
                                    {exportnames.map((exportname) => (
                                        <option key={exportname} value={exportname} />
                                    ))}
                                </datalist>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Doc Custom:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    type="text"
                                    value={newColumn.Metadata.DocCustom}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, DocCustom: e.target.value }
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Doc ID:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    type="text"
                                    value={newColumn.Metadata.DocID}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, DocID: e.target.value }
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Information Text:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    type="text"
                                    value={newColumn.Metadata.InformationText}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, InformationText: e.target.value }
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Block Option Name:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Control
                                    type="text"
                                    value={newColumn.Metadata.BlockOptionName}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, BlockOptionName: e.target.value }
                                    })}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                Type:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Select
                                    value={newColumn.Metadata.Type}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, Type: e.target.value }
                                    })}
                                >
                                    <option value="Open">Open</option>
                                    <option value="Multi">Multi</option>
                                    <option value="Checkbox">Checkbox</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        {newColumn.Metadata.Type === 'Multi' &&
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column>
                                    Answer Options:
                                </Form.Label>
                                <Col md={8}>
                                    {/* Make it that the user can add options with a button and also delete it */}
                                    <Button variant="primary" onClick={() => setNewColumn({
                                        ...newColumn,
                                        Metadata: {
                                            ...newColumn.Metadata,
                                            AnswerOptions: [...newColumn.Metadata.AnswerOptions, '']
                                        }
                                    })}><i className='fa fa-plus' /> Voeg optie toe</Button>
                                    {newColumn.Metadata.AnswerOptions.map((option, index) => (
                                        <>
                                            <Button variant="danger" onClick={() => {
                                                const options = newColumn.Metadata.AnswerOptions;
                                                options.splice(index, 1);
                                                setNewColumn({
                                                    ...newColumn,
                                                    Metadata: { ...newColumn.Metadata, AnswerOptions: options }
                                                });
                                            }
                                            }> <i className='fa fa-trash' /> Verwijder optie </Button>

                                            <Form.Control
                                                type="text"
                                                value={option}
                                                onChange={(e) => {
                                                    const options = newColumn.Metadata.AnswerOptions;
                                                    options[index] = e.target.value;
                                                    setNewColumn({
                                                        ...newColumn,
                                                        Metadata: { ...newColumn.Metadata, AnswerOptions: options }
                                                    });
                                                }}
                                            />
                                        </>
                                    ))}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column>
                                isActive:
                            </Form.Label>
                            <Col md={8}>
                                <Form.Check
                                    type="switch"
                                    checked={newColumn.Metadata.isActive}
                                    onChange={(e) => setNewColumn({
                                        ...newColumn,
                                        Metadata: { ...newColumn.Metadata, isActive: e.target.checked }
                                    })}
                                />
                            </Col>
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="references" title="References">
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Data Types
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Referentie</th>
                                                    <th>Waarde</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTypes.map((key) => (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>
                                                            <Form.Check
                                                                type="switch"
                                                                key={key}
                                                                id={`${key}`}
                                                                onChange={(e) =>
                                                                    setNewColumn({
                                                                        ...newColumn,
                                                                        Metadata: {
                                                                            ...newColumn.Metadata,
                                                                            [key]: e.target.checked
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        Data Targets
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Referentie</th>
                                                    <th>Waarde</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataTargets.map((key) => (
                                                    <tr key={key}>
                                                        <td>{key}</td>
                                                        <td>
                                                            <Form.Check
                                                                type="switch"
                                                                key={key}
                                                                id={`${key}`}
                                                                onChange={(e) =>
                                                                    setNewColumn({
                                                                        ...newColumn,
                                                                        Metadata: {
                                                                            ...newColumn.Metadata,
                                                                            [key]: e.target.checked
                                                                        }
                                                                    })
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="times" title="Times">
                        {/* Column times form fields */}
                        {getTimeFields().map((time) => (
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column>
                                    {time}:
                                </Form.Label>
                                <Col md={8}>
                                    <Form.Control
                                        type={time.includes("Cost") ? "text" : "number"}
                                        min={0}
                                        defaultValue={time.includes("Cost") ? "€" : 0}
                                        onChange={(e) => setNewColumn({
                                            ...newColumn,
                                            Metadata: {
                                                ...newColumn.Metadata,
                                                [time]: time.includes("Cost") ? e.target.value.split('€')[1] : e.target.value
                                            }
                                        })}
                                    />
                                </Col>
                            </Form.Group>
                        ))}
                    </Tab>
                </Tabs>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AddColumn;