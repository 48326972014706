import React, { useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Popover, Row, Tab, Table, Tabs } from 'react-bootstrap';

/**
 * README PropertyBoxes
 *
 * PropertyBoxes.jsx is een React-componentbestand dat drie hoofdcomponenten bevat: PropertyBoxMeta, PropertyBoxEquipment en PropertyBoxDevices.
 * Deze componenten worden gebruikt om eigenschappen van verschillende elementen in de applicatie weer te geven en te manipuleren.
 *
 * ===============================================================================================================================
 *
 * PropertyBoxMeta:
 * Deze component wordt gebruikt om de metadata van een geselecteerde rij weer te geven en te bewerken.
 * Het biedt een gebruikersinterface voor het bewerken van de metadata van een kolom.
 * De metadata omvat de naam van de kolom, het type en andere gerelateerde eigenschappen.
 * De component maakt gebruik van Bootstrap voor stijl en lay-out.
 *
 * PropertyBoxEquipment:
 * Deze component wordt gebruikt om de eigenschappen van apparatuur weer te geven en te bewerken.
 * Het biedt een gebruikersinterface voor het bewerken van de eigenschappen van een geselecteerde rij in de apparatuurlijst.
 * De eigenschappen omvatten de naam van de apparatuur, het type en andere gerelateerde eigenschappen.
 * Ook deze component maakt gebruik van Bootstrap voor stijl en lay-out.
 *
 * PropertyBoxDevices:
 * Deze component wordt gebruikt om de eigenschappen van apparaten weer te geven en te bewerken.
 * Het biedt een gebruikersinterface voor het bewerken van de eigenschappen van een geselecteerde rij in de lijst met apparaten.
 * De eigenschappen omvatten de naam van het apparaat, het type en andere gerelateerde eigenschappen.
 * Net als de andere componenten maakt deze gebruik van Bootstrap voor stijl en lay-out.
 *
 * ===============================================================================================================================
 *
 * Gebruik: Om deze componenten te gebruiken, importeer je ze vanuit het PropertyBoxes.jsx-bestand en voeg je ze toe aan je React-component.
 * Je moet de benodigde props voor elke component verstrekken.
 * Deze props omvatten de gegevens die moeten worden weergegeven en de functies die de gegevens manipuleren.
 *
 * Opmerking: Deze componenten zijn ontworpen om te werken met een specifieke gegevensstructuur.
 * Zorg ervoor dat de gegevens die je verstrekt overeenkomen met de verwachte structuur.
 * De gegevensstructuur omvat de naam van de kolom, het type en andere gerelateerde eigenschappen.
 *
 */

/**
 * @param {object} user
 * @param {function} handleSubmitMeta
 * @param handleDeleteRow
 * @param offcanvas
 * @param setOffCanvas
 * @param taskgroups
 * @param setSelectedElementData
 * @param selectedElementData
 * @param selectedRow
 * @param selectedRowIndex
 * @returns
 */
// PropertyBox voor de Kolommenlijst
export const PropertyBoxMeta = ({
    user,
    handleSubmitMeta,
    handleDeleteColumn,
    offcanvas,
    setOffCanvas,
    taskgroups,
    setSelectedElementData,
    selectedElementData,
    selectedRow,
    unchangedSelectedRow,
    selectedRowIndex,
    dataTypes,
    dataTargets
}) => {
    //Table split
    //Get selectedRow Metadata with selectedRow.Name
    const name = selectedRow.Name;
    const entries = Object.entries(selectedRow.Metadata);

    //TAB: References
    const booleanEntries = entries.filter(([key, value]) => typeof value === "boolean");

    //TAB:
    const nonBooleanEntries = entries.filter(([key, value]) => typeof value !== "boolean" && !key.includes("Time") && !key.includes("Cost"));
    const nonBooleanHalfLength = Math.ceil(nonBooleanEntries.length / 2);
    const [nonBooleanFirstHalfEntries, setNonBooleanFirstHalfEntries] = useState(nonBooleanEntries.slice(0, nonBooleanHalfLength))
    const nonBooleanSecondHalfEntries = nonBooleanEntries.slice(nonBooleanHalfLength, entries.length);

    //TAB: Time
    const timeEntries = entries.filter(([key, value]) => key.includes("Time") || key.includes("Cost"));
    const timeHalfLength = Math.ceil(timeEntries.length / 2);
    const timeFirstHalfEntries = timeEntries.slice(0, timeHalfLength)
    const timeSecondHalfEntries = timeEntries.slice(timeHalfLength, entries.length);

    const CustomTable = ({ tab }) => (
        tab === "general" ?
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <colgroup>
                            <col style={{ width: '1%' }} />
                            <col style={{ width: '48%' }} />
                            <col style={{ width: '48%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Info</th>
                                <th>Metadata</th>
                                <th>Waarde</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <OverlayTrigger placement="bottom"
                                        trigger={"focus"}
                                        overlay={
                                            <Popover
                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                <Popover.Body>De naam van de kolom</Popover.Body>
                                            </Popover>
                                        }>
                                        <i style={{ color: '#0d6efd' }}
                                            className="fas fa-info-circle" />
                                    </OverlayTrigger>
                                </td>
                                <td>Naam</td>
                                <td><Form.Control
                                    type={"text"}
                                    disabled={!offcanvas}
                                    defaultValue={selectedElementData.Name !== undefined ? selectedElementData.Name : name}
                                    onBlur={(e) => setSelectedElementData({
                                        ...selectedElementData,
                                        Name: e.target.value
                                    })}
                                /></td>
                            </tr>
                            {nonBooleanFirstHalfEntries.map(([key, value]) =>
                                <tr key={key}>
                                    <td>
                                        <OverlayTrigger placement="bottom"
                                            trigger={"focus"} overlay={
                                                <Popover
                                                    style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                    <Popover.Body>{key}</Popover.Body>
                                                </Popover>
                                            }>
                                            <i style={{ color: '#0d6efd' }}
                                                className="fas fa-info-circle" />
                                        </OverlayTrigger>
                                    </td>
                                    <td>{key}</td>
                                    <td>
                                        {value === true || value === false ?
                                            <Form.Check
                                                type="switch"
                                                disabled={!offcanvas}
                                                defaultChecked={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                onChange={(e) => setSelectedElementData({
                                                    ...selectedElementData,
                                                    [key]: e.target.checked
                                                })}
                                            />
                                            : value === "open" || value === "multi" || value === "checkbox" ?
                                                <Form.Select
                                                    disabled={!offcanvas}
                                                    defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                    onChange={(e) => setSelectedElementData({
                                                        ...selectedElementData,
                                                        [key]: e.target.value
                                                    })}
                                                >
                                                    <option value="open">open</option>
                                                    <option value="multi">multi</option>
                                                    <option value="checkbox">checkbox</option>
                                                </Form.Select>
                                                : key === "Taskgroup" ?
                                                    <>
                                                        <Form.Select
                                                            disabled={!offcanvas}
                                                            defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                            onChange={(e) => setSelectedElementData({
                                                                ...selectedElementData,
                                                                [key]: e.target.value
                                                            })}
                                                        >
                                                            <option value="">Selecteer een taskgroup</option>
                                                            {taskgroups ? Object.entries(taskgroups).map(([id, func]) => (
                                                                <option key={id}>{func.name}</option>
                                                            )) : <option key="default">No Taskgroups</option>}
                                                        </Form.Select>
                                                    </>
                                                    //TODO: You can't change or add options, when you do that the old value returns to the input field. But when you click out of the offcanvas the value that the user has entered is saved. Fix it.
                                                    : key === "AnswerOptions" && selectedElementData["Type"] === "multi" ?
                                                        <>
                                                            {value.map((option, index) => (
                                                                <div className={"d-flex justify-content-between"} key={index}>
                                                                    <Form.Control
                                                                        className={"text-wrap"}
                                                                        type="text"
                                                                        disabled={!offcanvas}
                                                                        defaultValue={option}
                                                                        onBlur={(e) => {
                                                                            const newValue = e.target.value;
                                                                            setSelectedElementData(prevState => ({
                                                                                ...prevState,
                                                                                [key]: value.map((opt, i) => i === index ? newValue : opt)
                                                                            }));
                                                                            setNonBooleanFirstHalfEntries(prevState => prevState.map(([k, v]) => k === key ? [k, v.map((opt, i) => i === index ? newValue : opt)] : [k, v]));
                                                                        }
                                                                        }
                                                                    />
                                                                    {/*Delete option*/}
                                                                    <Button
                                                                        disabled={!offcanvas}
                                                                        variant={"outline-danger"}
                                                                        onClick={() => {
                                                                            setSelectedElementData(prevState => ({
                                                                                ...prevState,
                                                                                [key]: value.filter((opt, i) => i !== index)
                                                                            }));
                                                                            // Remove the option from the nonBooleanEntries
                                                                            setNonBooleanFirstHalfEntries(prevState => prevState.map(([k, v]) => k === key ? [k, v.filter((opt, i) => i !== index)] : [k, v]));
                                                                        }}>
                                                                        <i className={"fa fa-trash"} />
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                            {/*Add option */}
                                                            <Button
                                                                disabled={!offcanvas}
                                                                onClick={() => {
                                                                    setSelectedElementData(prevState => ({
                                                                        ...prevState,
                                                                        [key]: Array.isArray(prevState[key]) ? [...prevState[key], ""] : [""]
                                                                    }));
                                                                    setNonBooleanFirstHalfEntries(prevState => prevState.map(([k, v]) => k === key ? [k, [...v, ""]] : [k, v]));
                                                                }}>
                                                                <i className={"fa fa-plus"} /> Add Option
                                                            </Button>
                                                        </>
                                                        :
                                                        key.includes("Cost") ?
                                                            <Form.Control
                                                                className={"text-wrap"}
                                                                type="text"
                                                                disabled={!offcanvas}
                                                                defaultValue={`€${selectedElementData[key] !== undefined ? selectedElementData[key] : value}`}
                                                                onBlur={(e) => setSelectedElementData({
                                                                    ...selectedElementData,
                                                                    [key]: e.target.value.split("€")[1]
                                                                })}
                                                            />
                                                            : key !== "AnswerOptions" &&
                                                            <Form.Control
                                                                className={"text-wrap"}
                                                                key={key}
                                                                type="text"
                                                                disabled={!offcanvas}
                                                                defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                                onBlur={(e) => setSelectedElementData({
                                                                    ...selectedElementData,
                                                                    [key]: e.target.value
                                                                })}
                                                            />
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table striped bordered hover>
                        <colgroup>
                            <col style={{ width: '1%' }} />
                            <col style={{ width: '48%' }} />
                            <col style={{ width: '48%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Info</th>
                                <th>Metadata</th>
                                <th>Waarde</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nonBooleanSecondHalfEntries.map(([key, value]) => (
                                <tr key={key}>
                                    <td>
                                        <OverlayTrigger placement="bottom"
                                            trigger={"focus"} overlay={
                                                <Popover
                                                    style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                    <Popover.Body>{key}</Popover.Body>
                                                </Popover>
                                            }>
                                            <i style={{ color: '#0d6efd' }}
                                                className="fas fa-info-circle" />
                                        </OverlayTrigger>
                                    </td>
                                    <td>{key}</td>
                                    <td>
                                        {value === true || value === false ?
                                            <Form.Check
                                                type="switch"
                                                disabled={!offcanvas}
                                                defaultChecked={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                onChange={(e) => setSelectedElementData({
                                                    ...selectedElementData,
                                                    [key]: e.target.checked
                                                })}
                                            />
                                            : value === "open" || value === "multi" || value === "checkbox" ?
                                                <Form.Select
                                                    disabled={!offcanvas}
                                                    defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                    onChange={(e) => setSelectedElementData({
                                                        ...selectedElementData,
                                                        [key]: e.target.value
                                                    })}
                                                >
                                                    <option value="open">open</option>
                                                    <option value="multi">multi</option>
                                                    <option value="checkbox">checkbox</option>
                                                </Form.Select>
                                                : key === "Taskgroup" ?
                                                    <>
                                                        <Form.Select
                                                            disabled={!offcanvas}
                                                            defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                            onChange={(e) => setSelectedElementData({
                                                                ...selectedElementData,
                                                                [key]: e.target.value
                                                            })}
                                                        >
                                                            <option value="">Selecteer een taskgroup</option>
                                                            {taskgroups ? Object.entries(taskgroups).map(([id, func]) => (
                                                                <option key={id}>{func.name}</option>
                                                            )) : <option key="default">No Taskgroups</option>}
                                                        </Form.Select>
                                                    </>
                                                    : key === "AnswerOptions" ?
                                                        <Form.Select
                                                            disabled={!offcanvas}
                                                            defaultValue={value}
                                                            onChange={(e) => setSelectedElementData({
                                                                ...selectedElementData,
                                                                [key]: e.target.value
                                                            })}
                                                        >
                                                            {value.map((option, index) => (
                                                                <option key={index} value={option}>{option}</option>
                                                            ))}
                                                        </Form.Select>
                                                        :
                                                        <Form.Control
                                                            type="text"
                                                            disabled={!offcanvas}
                                                            defaultValue={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                            onBlur={(e) => setSelectedElementData({
                                                                ...selectedElementData,
                                                                [key]: e.target.value
                                                            })}
                                                        />
                                        }
                                    </td>
                                </tr>
                            ))}
                            <tr key="active">
                                <td>
                                    <OverlayTrigger placement="bottom"
                                        trigger={"focus"} overlay={
                                            <Popover
                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                <Popover.Body>Is de kolom actief?</Popover.Body>
                                            </Popover>
                                        }>
                                        <i style={{ color: '#0d6efd' }}
                                            className="fas fa-info-circle" />
                                    </OverlayTrigger>
                                </td>
                                <td>isActive</td>
                                <td>
                                    <Form.Check
                                        type="switch"
                                        id="isActive"
                                        disabled={!offcanvas}
                                        defaultChecked={selectedElementData["isActive"] !== undefined ? selectedElementData["isActive"] : selectedRow.Metadata["isActive"]}
                                        onChange={(e) => setSelectedElementData({
                                            ...selectedElementData,
                                            isActive: e.target.checked
                                        })}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>

            </Row>
            :
            tab === "references" ?
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                Data Types
                            </Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <colgroup>
                                        <col style={{ width: '1%' }} />
                                        <col style={{ width: '48%' }} />
                                        <col style={{ width: '48%' }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Info</th>
                                            <th>Referentie</th>
                                            <th>Waarde</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {booleanEntries.map(([key, value]) => (
                                            dataTypes.includes(key) &&
                                            <tr key={key}>
                                                <OverlayTrigger placement="bottom"
                                                    trigger={"focus"} overlay={
                                                        <Popover
                                                            style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                            <Popover.Body>{key}</Popover.Body>
                                                        </Popover>
                                                    }>
                                                    <i style={{ color: '#0d6efd' }}
                                                        className="fas fa-info-circle" />
                                                </OverlayTrigger>
                                                <td>{key}</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id={key}
                                                        disabled={!offcanvas}
                                                        defaultChecked={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                        onChange={(e) => setSelectedElementData({
                                                            ...selectedElementData,
                                                            [key]: e.target.checked
                                                        })}
                                                    />
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card></Col>
                    <Col>
                        <Card>
                            <Card.Header>
                                Data Targets
                            </Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Reference</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {booleanEntries.map(([key, value]) => (
                                            dataTargets.includes(key) &&
                                            <tr key={key}>
                                                <td>{key}</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id={key}
                                                        disabled={!offcanvas}
                                                        defaultChecked={selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                        onChange={(e) => setSelectedElementData({
                                                            ...selectedElementData,
                                                            [key]: e.target.checked
                                                        })}
                                                    />
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                :
                tab === "time" &&
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <colgroup>
                                <col style={{ width: '1%' }} />
                                <col style={{ width: '48%' }} />
                                <col style={{ width: '48%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Info</th>
                                    <th>Metadata</th>
                                    <th>Waarde</th>
                                </tr>
                            </thead>
                            <tbody>
                                {timeFirstHalfEntries.map(([key, value]) => (
                                    <tr key={key}>
                                        <td><OverlayTrigger placement="bottom"
                                            trigger={"focus"} overlay={
                                                <Popover
                                                    style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                    <Popover.Body>{key}</Popover.Body>
                                                </Popover>
                                            }>
                                            <i style={{ color: '#0d6efd' }}
                                                className="fas fa-info-circle" />
                                        </OverlayTrigger></td>
                                        <td>{key}</td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                id={key}
                                                disabled={!offcanvas}
                                                defaultValue={key.includes("Cost") ? `€${selectedElementData[key] !== undefined ? selectedElementData[key] : value}` : selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                onBlur={(e) => setSelectedElementData({
                                                    ...selectedElementData,
                                                    [key]: key.includes("Cost") ? e.target.value.slice(1) : e.target.value
                                                })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <Table striped bordered hover>
                            <colgroup>
                                <col style={{ width: '1%' }} />
                                <col style={{ width: '48%' }} />
                                <col style={{ width: '48%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Info</th>
                                    <th>Reference</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {timeSecondHalfEntries.map(([key, value]) => (
                                    <tr key={key}>
                                        <td>
                                            <OverlayTrigger placement="bottom"
                                                trigger={"focus"} overlay={
                                                    <Popover
                                                        style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                        <Popover.Body>{key}</Popover.Body>
                                                    </Popover>
                                                }>
                                                <i style={{ color: '#0d6efd' }}
                                                    className="fas fa-info-circle" />
                                            </OverlayTrigger>
                                        </td>
                                        <td>{key}</td>
                                        <td>
                                            <Form.Control
                                                type="text"
                                                id={key}
                                                disabled={!offcanvas}
                                                defaultValue={key.includes("Cost") ? `€${selectedElementData[key] !== undefined ? selectedElementData[key] : value}` : selectedElementData[key] !== undefined ? selectedElementData[key] : value}
                                                onBlur={(e) => setSelectedElementData({
                                                    ...selectedElementData,
                                                    [key]: key.includes("Cost") ? e.target.value.slice(1) : e.target.value
                                                })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
    );

    return (
        <div id='metadata'>
            <h2 className='p-0'>Metadata Kolom: {selectedRow.Name}</h2>
            <>
                <Button className='m-1' variant="primary"
                    disabled={selectedElementData === unchangedSelectedRow || !user.Roles.includes("Admin")}
                    onClick={() => handleSubmitMeta()}><i className='fa fa-save' /> Wijzigingen Opslaan</Button>
                <Button className='m-1' variant="danger"
                    onClick={() => {
                        setOffCanvas(!offcanvas);
                        setSelectedElementData(unchangedSelectedRow)
                    }}><i className='fa fa-cancel' /> Annuleren</Button>
                <Button disabled={!user.Roles.includes("Admin")} className='m-1' variant="outline-danger"
                    onClick={() => handleDeleteColumn(selectedRowIndex)}> <i className='fa fa-trash' /> Verwijderen</Button>
                <Tabs id="uncontrolled-tab-example" className="mb-3">
                    <Tab title="Generiek" eventKey="general">
                        <Row>
                            <CustomTable data={nonBooleanEntries} tab="general" />
                        </Row>
                    </Tab>
                    <Tab title="Referenties" eventKey="references">
                        <Row>
                            <CustomTable data={booleanEntries} tab="references" />
                        </Row>
                    </Tab>
                    <Tab title="Tijd" eventKey="time">
                        <Row>
                            <CustomTable data={timeEntries} tab="time" />
                        </Row>
                    </Tab>
                </Tabs>

            </>

        </div>
    );

}

/**
 *
 * @param showOffcanvas
 * @param setShowOffcanvas
 * @param rowElement
 * @param cabinets
 * @param devices
 * @param activeGroup
 * @param setActiveGroup
 * @param columns
 * @param groups
 * @param elements
 * @param selectedRowData
 * @param selectedRowIndex
 * @param setSelectedRowData
 * @param handleSubmitProperties
 * @param handleTabChange
 * @param handleDeleteRow
 * @returns {Element}
 * @constructor
 */
// PropertyBox voor de Equipmentlijst
export const PropertyBoxEquipment = ({
    showOffcanvas,
    setShowOffcanvas,
    rowElement,
    cabinets,
    devices,
    activeGroup,
    setActiveGroup,
    columns,
    groups,
    elements,
    selectedRowData,
    selectedRowIndex,
    setSelectedRowData,
    handleSubmitProperties,
    handleTabChange,
    handleDeleteRow
}) => {
    return (
        <>
            <h4 className='p-0'>Equipment - {selectedRowData.Instance}</h4>

            <Button className='m-1' variant="primary"
                onClick={() => handleSubmitProperties(selectedRowIndex)}><i className='fa fa-save' /> Wijzigingen
                Opslaan</Button>
            <Button className='m-1' variant="danger"
                onClick={() => {
                    setShowOffcanvas(!showOffcanvas)
                }}> <i className='fa fa-cancel' /> Annuleren</Button>
            <Button className="m-1" variant={"outline-danger"} onClick={() => handleDeleteRow(selectedRowIndex)}> <i
                className='fa fa-trash' /> Regel Verwijderen</Button>
            <hr />
            <div id="properties">
                <Tabs activeKey={activeGroup} onSelect={handleTabChange} onLoad={() => setActiveGroup(0)}>
                    {groups.map((group, index) => {
                        const displayGroup = group === "" ? "Undefined" : group;
                        return (
                            <Tab eventKey={index} id={displayGroup}
                                title={displayGroup} key={index}>
                                <div>
                                    <div id={rowElement}>
                                        <Row>
                                            {Object.entries(selectedRowData).length > 0 ? Object.entries(selectedRowData).map(([key, value]) => {
                                                if (key === "GroupType" && value === rowElement) {
                                                    return (
                                                        <Col key={key}>
                                                            <Table striped bordered hover>
                                                                <colgroup>
                                                                    <col style={{ width: '1%' }} />
                                                                    <col style={{ width: '48%' }} />
                                                                    <col style={{ width: '48%' }} />
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Info</th>
                                                                        <th>Item</th>
                                                                        <th>Waarde</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {columns.map((column, index) => {
                                                                        if (
                                                                            (column && column.Metadata.Group === displayGroup && column.Metadata.isActive === true && column.Metadata.Element === rowElement)
                                                                            ||
                                                                            (column && displayGroup === "Global" && column.Metadata.isActive === true && column.Metadata.Element === "Undefined")) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            trigger={"focus"}
                                                                                            overlay={
                                                                                                <Popover
                                                                                                    style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                                                    <Popover.Body>{column.Metadata.InformationText}</Popover.Body>
                                                                                                </Popover>
                                                                                            }>
                                                                                            <i className="fas fa-info-circle" style={{ color: '#0d6efd' }} />
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                    <td>{column.Name}</td>
                                                                                    <td>
                                                                                        {column.Metadata.Type === "multi" ?
                                                                                            <Form.Select
                                                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.DefaultAnswer}
                                                                                                onChange={(e) => setSelectedRowData({
                                                                                                    ...selectedRowData,
                                                                                                    [column.Name]: e.target.value
                                                                                                })}
                                                                                            >
                                                                                                {column.Name === "CabinName" ?
                                                                                                    <>
                                                                                                        <option
                                                                                                            value="">Selecteer
                                                                                                            een cabinet
                                                                                                        </option>
                                                                                                        {cabinets.map((cabinet, index) => (
                                                                                                            <option
                                                                                                                key={index}
                                                                                                                value={cabinet.Name}>{cabinet.Name}</option>
                                                                                                        ))}
                                                                                                    </>
                                                                                                    :
                                                                                                    column.Name === "Machine" ?
                                                                                                        <>
                                                                                                            <option
                                                                                                                value={""}>
                                                                                                                Selecteer
                                                                                                                een
                                                                                                                device
                                                                                                            </option>
                                                                                                            {devices.map((device, index) => (
                                                                                                                <option
                                                                                                                    key={index}
                                                                                                                    value={device.Name}>{device.Name}</option>
                                                                                                            ))}
                                                                                                        </>
                                                                                                        :
                                                                                                        column.Name === "GroupType" ?
                                                                                                            <>
                                                                                                                <option
                                                                                                                    value="">Selecteer
                                                                                                                    een groep type
                                                                                                                </option>
                                                                                                                {column.Metadata.AnswerOptions.map((option, index) => (
                                                                                                                    <option
                                                                                                                        key={index}
                                                                                                                        value={option}>{option}</option>
                                                                                                                ))}
                                                                                                            </>
                                                                                                            :
                                                                                                            column.Metadata.AnswerOptions.length > 0 ?
                                                                                                                <>
                                                                                                                    <option
                                                                                                                        value="">Selecteer
                                                                                                                        een optie
                                                                                                                    </option>
                                                                                                                    {column.Metadata.AnswerOptions.map((option, index) => (
                                                                                                                        <option
                                                                                                                            key={index}
                                                                                                                            value={option}>{option}
                                                                                                                        </option>
                                                                                                                    ))} </> : <option
                                                                                                                        value={""}>
                                                                                                                    <b className="text-danger">Geen
                                                                                                                        opties
                                                                                                                        beschikbaar</b>
                                                                                                                </option>
                                                                                                }
                                                                                            </Form.Select>
                                                                                            : column.Metadata.Type === "checkbox" || column.Metadata.Type === "check" ?
                                                                                                <Form.Check
                                                                                                    type="switch"
                                                                                                    defaultChecked={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                                    onChange={(e) => setSelectedRowData({
                                                                                                        ...selectedRowData,
                                                                                                        [column.Name]: e.target.checked
                                                                                                    })}
                                                                                                />
                                                                                                : column.Metadata.Type === "open" &&
                                                                                                <Form.Control
                                                                                                    type="text"
                                                                                                    defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                                    onBlur={(e) => setSelectedRowData({
                                                                                                        ...selectedRowData,
                                                                                                        [column.Name]: e.target.value
                                                                                                    })}
                                                                                                />
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        else if (displayGroup !== "Global" && index === columns.length - 1
                                                                            && !columns.some(column => column.Metadata.Group === displayGroup
                                                                                && column.Metadata.isActive === true
                                                                                && column.Metadata.Element === rowElement)) {
                                                                            return (
                                                                                <b className="text-danger">Geen properties in deze groep beschikbaar</b>
                                                                            );
                                                                        }
                                                                        else return null;
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    );
                                                }
                                                else return null;
                                            })
                                                :
                                                <div className="text-center">
                                                    <b className="text-danger">Geen properties in deze groep beschikbaar</b>
                                                </div>
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Tab>
                        );
                    })}
                </Tabs>
            </div>
        </>
    );
}

/**
 *
 * @param setShowOffcanvas
 * @param {array} columns
 * @param cabinets
 * @param {object} selectedRowData
 * @param {function} setSelectedRowData
 * @param selectedRowIndex
 * @param {function} handleSubmitProperties
 * @param handleDeleteRow
 * @returns
 *
 */
// PropertyBox voor de Deviceslijst
export const PropertyBoxDevices = ({
    setShowOffcanvas,
    columns,
    cabinets,
    selectedRowData,
    setSelectedRowData,
    selectedRowIndex,
    handleSubmitProperties,
    handleDeleteRow,
}) => {
    return (
        <>
            <h4 className='p-0'>Device - {selectedRowData.DeviceName}</h4>
            <Button className='m-1' variant="primary"
                onClick={() => handleSubmitProperties(selectedRowIndex)}> <i className='fa fa-save' /> Wijzigingen
                Opslaan</Button>
            <Button className='m-1' variant="danger"
                onClick={() => setShowOffcanvas(false)}> <i className='fa fa-cancel' /> Annuleren</Button>
            <Button className="m-1" variant={"outline-danger"} onClick={() => handleDeleteRow(selectedRowIndex)}> <i
                className='fa fa-trash' /> Regel Verwijderen</Button>
            <hr />
            <div id="properties">
                <div>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <colgroup>
                                    <col style={{ width: '1%' }} />
                                    <col style={{ width: '48%' }} />
                                    <col style={{ width: '48%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>Info</th>
                                        <th>Item</th>
                                        <th>Waarde</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {columns.map((column, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <OverlayTrigger placement="bottom"
                                                        trigger={"focus"} overlay={
                                                            <Popover
                                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                <Popover.Body>{column.Metadata.InformationText}</Popover.Body>
                                                            </Popover>
                                                        }>
                                                        <i style={{ color: '#0d6efd' }}
                                                            className="fas fa-info-circle" />
                                                    </OverlayTrigger>
                                                </td>
                                                <td>{column.Name}</td>
                                                <td>
                                                    {column.Metadata.Type === "check" ?
                                                        <Form.Check
                                                            type="switch"
                                                            defaultChecked={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                            onChange={(e) => setSelectedRowData({
                                                                ...selectedRowData,
                                                                [column.Name]: e.target.checked
                                                            })}
                                                        />
                                                        : column.Metadata.Type === "multi" ?
                                                            <Form.Select
                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                onChange={(e) => setSelectedRowData({
                                                                    ...selectedRowData,
                                                                    [column.Name]: e.target.value
                                                                })}
                                                            >
                                                                {column.Name === "Cabinet" ?
                                                                    cabinets.map((cabinet, index) => (
                                                                        <option key={index}
                                                                            value={cabinet.Name}>{cabinet.Name}</option>
                                                                    )) : column.Metadata.AnswerOptions.map((option, index) => (
                                                                        <option key={index}
                                                                            value={option}>{option}</option>
                                                                    ))}
                                                            </Form.Select>
                                                            :
                                                            <Form.Control
                                                                type="text"
                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                onBlur={(e) => setSelectedRowData({
                                                                    ...selectedRowData,
                                                                    [column.Name]: e.target.value
                                                                })}
                                                            />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param setShowOffcanvas
 * @param columns
 * @param selectedRowData
 * @param setSelectedRowData
 * @param selectedRowIndex
 * @param handleSubmitProperties
 * @param handleDeleteRow
 * @returns {Element}
 * @constructor
 */
// PropertyBox voor de Cabinetlijst
export const PropertyBoxCabinet = ({
    setShowOffcanvas,
    columns,
    selectedRowData,
    setSelectedRowData,
    selectedRowIndex,
    handleSubmitProperties,
    handleDeleteRow
}) => {
    const elements = [...new Set(columns.map(column => column.Metadata.Element))];

    return (
        <>
            <h4 className='p-0'>Cabinet - {selectedRowData.Name}</h4>
            <Button className='m-1' variant="primary"
                onClick={() => handleSubmitProperties(selectedRowIndex)}> <i className='fa fa-save' /> Wijzigingen
                Opslaan</Button>
            <Button className='m-1' variant="danger"
                onClick={() => setShowOffcanvas(false)}> <i className='fa fa-cancel' /> Annuleren</Button>
            <Button className="m-1" variant={"outline-danger"} onClick={() => handleDeleteRow(selectedRowIndex)}><i
                className='fa fa-trash' /> Regel Verwijderen</Button>
            <hr />
            <div id="properties">
                <div id={"cabinet"}>
                    <Tabs>
                        {elements.map((element, index) => {
                            const filteredColumns = columns.filter(column => column.Metadata.Element === element);
                            const halfLength = Math.ceil(filteredColumns.length / 2);
                            const firstHalfColumns = filteredColumns.slice(0, halfLength);
                            const secondHalfColumns = filteredColumns.slice(halfLength, columns.length);
                            return (
                                <Tab eventKey={index} title={element}>
                                    <Row>
                                        <Col>
                                            {/* First half table */}
                                            <Table striped bordered>
                                                <colgroup>
                                                    <col style={{ width: '1%' }} />
                                                    <col style={{ width: '48%' }} />
                                                    <col style={{ width: '48%' }} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>Info</th>
                                                        <th>Item</th>
                                                        <th>Waarde</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {firstHalfColumns.filter(column => column.Metadata.Element === element).map((column) => {
                                                        return (
                                                            <tr key={column.Name}>
                                                                <td>
                                                                    <OverlayTrigger placement="bottom"
                                                                        trigger={"focus"} overlay={
                                                                            <Popover
                                                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                                <Popover.Body>{column.Metadata.InformationText}</Popover.Body>
                                                                            </Popover>
                                                                        }>
                                                                        <i style={{ color: '#0d6efd' }}
                                                                            className="fas fa-info-circle" />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className={"text-wrap"}>{column.Name}
                                                                </td>
                                                                <td className={"text-wrap"}>
                                                                    {column.Metadata.Type === "checkbox" ?
                                                                        <Form.Check
                                                                            type="switch"
                                                                            defaultChecked={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                            onChange={(e) => setSelectedRowData({
                                                                                ...selectedRowData,
                                                                                [column.Name]: e.target.checked
                                                                            })}
                                                                        />
                                                                        : column.Metadata.Type === "multi" ?
                                                                            <Form.Select
                                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                onChange={(e) => setSelectedRowData({
                                                                                    ...selectedRowData,
                                                                                    [column.Name]: e.target.value
                                                                                })}
                                                                            >
                                                                                <option value="">Selecteer een optie
                                                                                </option>
                                                                                {column.Metadata.AnswerOptions.map((option, index) => (
                                                                                    <option key={index}
                                                                                        value={option}>{option}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                            :
                                                                            <Form.Control
                                                                                type="text"
                                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                onBlur={(e) => setSelectedRowData({
                                                                                    ...selectedRowData,
                                                                                    [column.Name]: e.target.value
                                                                                })}
                                                                            />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            {/* Second half table */}
                                            <Table striped bordered>
                                                <colgroup>
                                                    <col style={{ width: '1%' }} />
                                                    <col style={{ width: '48%' }} />
                                                    <col style={{ width: '48%' }} />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>Info</th>
                                                        <th>Item</th>
                                                        <th>Waarde</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {secondHalfColumns.filter(column => column.Metadata.Element === element).map((column) => {
                                                        return (
                                                            <tr key={column.Name}>
                                                                <td>
                                                                    <OverlayTrigger placement="bottom"
                                                                        className={"text-center"}
                                                                        trigger={"focus"} overlay={
                                                                            <Popover
                                                                                style={{ boxShadow: "-1px 2px 5px rgba(0,0,0,0.1)" }}>
                                                                                <Popover.Body>{column.Metadata.InformationText}</Popover.Body>
                                                                            </Popover>
                                                                        }>
                                                                        <i style={{ color: '#0d6efd' }}
                                                                            className="fas fa-info-circle" />
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className={"text-wrap"}>{column.Name}
                                                                </td>
                                                                <td className={"text-wrap"}>
                                                                    {column.Metadata.Type === "check" ?
                                                                        <Form.Check
                                                                            type="switch"
                                                                            defaultChecked={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                            onChange={(e) => setSelectedRowData({
                                                                                ...selectedRowData,
                                                                                [column.Name]: e.target.checked
                                                                            })}
                                                                        />
                                                                        : column.Metadata.Type === "multi" ?
                                                                            <Form.Select
                                                                                defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                onChange={(e) => setSelectedRowData({
                                                                                    ...selectedRowData,
                                                                                    [column.Name]: e.target.value
                                                                                })}
                                                                            >
                                                                                <option value="">Selecteer een optie
                                                                                </option>
                                                                                {column.Metadata.AnswerOptions.map((option, index) => (
                                                                                    <option key={index}
                                                                                        value={option}>{option}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                            : column.Name === "Aflever adres" ?
                                                                                <textarea className="form-control"
                                                                                    defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                    onBlur={(e) => setSelectedRowData({
                                                                                        ...selectedRowData,
                                                                                        [column.Name]: e.target.value
                                                                                    })}
                                                                                /> :
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    defaultValue={selectedRowData[column.Name] !== undefined ? selectedRowData[column.Name] : column.Metadata.Default}
                                                                                    onBlur={(e) => setSelectedRowData({
                                                                                        ...selectedRowData,
                                                                                        [column.Name]: e.target.value
                                                                                    })}
                                                                                />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        </>
    );
}


