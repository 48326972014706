import React, { useEffect, useState } from "react";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation,} from "react-router-dom";
import {AuthProvider, useAuth} from "./context/AuthContext";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {Toaster} from "react-hot-toast";
// import GlobalHotkeyHandler from './components/misc/GlobalHotkeyHandler';
import ConfettiEffect from "./components/funny/ConfettiEffect";

//Components
import NavMenu from "./components/misc/NavMenu";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

//Materials
import Materials from "./pages/materials/index";
import MaterialsCreate from "./pages/materials/create";
import MaterialsDetails from "./pages/materials/details/[id]";
import MaterialsBulk from "./pages/materials/bulk";
import MaterialsEdit from "./pages/materials/edit/[id]";

//Materials - Unifeed
import Unifeed from "./pages/materials/unifeed/index";
import UnifeedCreate from "./pages/materials/unifeed/create";
import UnifeedDetails from "./pages/materials/unifeed/details/[id]";
import UnifeedEdit from "./pages/materials/unifeed/edit/[id]";
import UnifeedAvailable from "./pages/materials/unifeed/available";

//EPLAN
import EplanConfig from "./pages/eplan/config";
import EplanCableCoverter from "./pages/eplan/cable/converter";
import EplanCablePlusMinLijst from "./pages/eplan/cable/plusminlijst";
import EplanExactArtikellijst from "./pages/eplan/exact/artikellijst";
import EplanExactBestel from "./pages/eplan/exact/bestel";
import EplanExactPlusMinAPI from "./pages/eplan/exact/plusminapi";
import EplanMaterialConverter from "./pages/eplan/material/converter";
import EplanMaterialPlusMinLijst from "./pages/eplan/material/plusminlijst";

//Clockify
import ClockifyDashboard from "./pages/clockify/dashboard/index";
import ClockifyProjects from "./pages/clockify/projects/index";
import ClockifyProjectsCreate from "./pages/clockify/projects/create";
import ClockifyProjectsDetails from "./pages/clockify/projects/details/[id]";
import ClockifyClients from "./pages/clockify/clients/index";
import ClockifyClientsCreate from "./pages/clockify/clients/create";
import ClockifyClientsDetails from "./pages/clockify/clients/details/[id]";

//Projects
import Projects from "./pages/projects/index";
import ProjectsCreate from "./pages/projects/create";
import ProjectDetails from "./pages/projects/details/[id]";

//Checklist
import Checklist from "./pages/projects/checklist/[id]";

//Questions
import GlobalQuestions from "./pages/questions/index";
// import ChecklistQuestions from "./pages/questions/checklist/[id]";

//Cabinet
import ColumnsList from "./pages/cabinet/columns-metadata/index";
import Equipmentlist from "./pages/cabinet/equipmentlist/[id]";
import DeviceList from "./pages/cabinet/devicelist/[id]";
import CabinetList from "./pages/cabinet/cabinetlist/[id]"

//Companies
import Companies from "./pages/companies/index";
import CompaniesCreate from "./pages/companies/create";
import CompaniesDetails from "./pages/companies/details/[id]";

//People
import Peoples from "./pages/people/index";
import PeopleCreate from "./pages/people/create";
import PeopleDetails from "./pages/people/details/[id]";
import PeopleExtra from "./pages/people/extra";

//Functions
import Functions from "./pages/functions/index";

//Feedback
import Feedback from "./pages/feedback/[feedback]";
import FeedbackList from "./pages/feedback/index";

//Profile
import Profile from "./pages/profile/index";
import MyProjects from "./pages/profile/myProjects/index";
import Timesheet from "./pages/profile/Timesheet/index";

//Exacttest
import Exacttest from "./pages/exacttest/index";

//Workorder App
import WorkorderAppWerkplaats from "./pages/workorder_app/werkplaats/index";
import WorkorderAppProject from "./pages/workorder_app/project/[id]";
import WorkorderAppWerkorder from "./pages/workorder_app/werkorder/[id]";
import WorkorderAppDashboard from "./pages/workorder_app/dashboard/index";
import WorkorderAppDashboardDetails from "./pages/workorder_app/dashboard/details/[id]";

//Export
import Export from "./pages/export_pagina/index";

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <div>
      {!isLoginPage && (
        <header>
          <NavMenu />
        </header>
      )}
      <Container fluid className="pt-3 pb-3">
        <Toaster toastOptions={{ position: "bottom-right" }} />
        {children}
      </Container>
      {!isLoginPage && (
        <footer id="mainfooter" className="border-top footer text-muted">
          <Container fluid>
            Copyright &copy; {new Date().getFullYear()} -
            <a
              href="https://piaservice.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}PIA-Service{" "}
            </a>
          </Container>
        </footer>
      )}
    </div>
  );
};

const protectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/materials", element: <Materials />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/create", element: <MaterialsCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/details/:id", element: <MaterialsDetails />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/bulk", element: <MaterialsBulk />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/edit/:id", element: <MaterialsEdit />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/unifeed", element: <Unifeed />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/unifeed/create", element: <UnifeedCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/unifeed/details/:id", element: <UnifeedDetails />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/unifeed/edit/:id", element: <UnifeedEdit />, requiredRoles: ["Admin", "User"] },
  { path: "/materials/unifeed/available", element: <UnifeedAvailable />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/config", element: <EplanConfig />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/cable/converter", element: <EplanCableCoverter />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/cable/plusminlijst", element: <EplanCablePlusMinLijst />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/exact/artikellijst", element: <EplanExactArtikellijst />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/exact/bestel", element: <EplanExactBestel />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/exact/plusminlijst", element: <EplanExactPlusMinAPI />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/material/converter", element: <EplanMaterialConverter />, requiredRoles: ["Admin", "User"] },
  { path: "/eplan/material/plusminlijst", element: <EplanMaterialPlusMinLijst />, requiredRoles: ["Admin", "User"] },
  { path: "/projects", element: <Projects />, requiredRoles: ["Admin", "User"] },
  { path: "/projects/create", element: <ProjectsCreate />, requiredRoles: ["Exact"] },
  { path: "/projects/details/:id", element: <ProjectDetails />, requiredRoles: ["Admin", "User"] },
  { path: "/projects/checklist/:id", element: <Checklist />, requiredRoles: ["Admin", "User"] },
  { path: "/questions", element: <GlobalQuestions />, requiredRoles: ["Admin", "User"] },
  // { path: "/questions/checklist/:id", element: <ChecklistQuestions />, requiredRoles: ["Admin", "User"] },
  { path: "/companies", element: <Companies />, requiredRoles: ["Admin", "User"] },
  { path: "/companies/create", element: <CompaniesCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/companies/details/:id", element: <CompaniesDetails />, requiredRoles: ["Admin", "User"], },
  { path: "/people", element: <Peoples />, requiredRoles: ["Admin", "User"] },
  { path: "/people/create", element: <PeopleCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/people/details/:id", element: <PeopleDetails />, requiredRoles: ["Admin", "User"] },
  { path: "/people/extra", element: <PeopleExtra />, requiredRoles: ["Admin"]},
  { path: "/functions", element: <Functions />, requiredRoles: ["Admin", "User"] },
  { path: "/feedback/:feedback", element: <Feedback />, requiredRoles: ["User", "Admin"]},
  { path: "/feedback", element: <FeedbackList />, requiredRoles: ["Admin"] },
  { path: "/profile", element: <Profile />, requiredRoles: ["User", "Admin"] },
  { path: "/profile/myProjects", element: <MyProjects />, requiredRoles: ["User", "Admin"] },
  { path: "/profile/Timesheet", element: <Timesheet />, requiredRoles: ["User", "Admin"] },
  { path: "/exacttest", element: <Exacttest />, requiredRoles: ["Admin", "User"] },
  { path: "/cabinet/columns-metadata", element: <ColumnsList />, requiredRoles: ["Admin", "User"] },
  { path: "/cabinet/equipmentlist/:id", element: <Equipmentlist />, requiredRoles: ["Admin", "User"] },
  { path: "/cabinet/devicelist/:id", element: <DeviceList />, requiredRoles: ["Admin", "User"] },
  { path: "/cabinet/cabinetlist/:id", element: <CabinetList />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/dashboard", element: <ClockifyDashboard />, requiredRoles: ["Admin", "User"] },
  
  { path: "/clockify/projects", element: <ClockifyProjects />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/projects/create", element: <ClockifyProjectsCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/projects/details/:id", element: <ClockifyProjectsDetails />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/clients", element: <ClockifyClients />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/clients/create", element: <ClockifyClientsCreate />, requiredRoles: ["Admin", "User"] },
  { path: "/clockify/clients/details/:id", element: <ClockifyClientsDetails />, requiredRoles: ["Admin", "User"] },
  
  // Workorder App
  { path: "/wo", element: <WorkorderAppWerkplaats />, requiredRoles: ["WorkorderApp"] },
  { path: "/wo/:id", element: <WorkorderAppProject />, requiredRoles: ["WorkorderApp"] },
  { path: "/wo/details/:id", element: <WorkorderAppWerkorder />, requiredRoles: ["WorkorderApp"]},
  { path: "/wo/dashboard", element: <WorkorderAppDashboard />, requiredRoles: ["Exact"]},
  { path: "/wo/dashboard/details/:id", element: <WorkorderAppDashboardDetails />, requiredRoles: ["Exact"]},

  // EXPORT
  { path: "/export", element: <Export />, requiredRoles: ["Admin"]}

  
];

const AppRoutes = () => {
  const location = useLocation();
  const { user, loading } = useAuth();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (user && user.Confetti) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), user.ConfettiTime);
    }
  }, [user, location]);

  if (loading) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <Row>
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading...</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {showConfetti && <ConfettiEffect />}
      <Routes>
        <Route path="/" element={<Login />} />
        {protectedRoutes.map(({ path, element, requiredRoles }) => (
          <Route
            key={path}
            path={path}
            element={
              user ? (
                user && (!requiredRoles || requiredRoles.some((role) => user.Roles.includes(role))) ? (
                  <Layout>{element}</Layout>
                ) : (
                  <Navigate to="/dashboard" state={{ from: location }} replace />
                )
              ) : (
                <Navigate to="/" state={{ from: location }} replace />
              )
            }
          />
        ))}
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router>
      {/* <GlobalHotkeyHandler> */}
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {/* </GlobalHotkeyHandler> */}
    </Router>
  );
};

export default App;
