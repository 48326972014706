import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, Col, Container, Form, InputGroup, ListGroup, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";
import { displayToast } from "../../../components/managers/ToastManager";
import apiCall from "../../../components/utils/apiCall";
import { useAuth } from "../../../context/AuthContext";
import { FunctionBadge, StatusBadge } from "../../../components/managers/BadgeManager";
import { calculatePermissionsValue } from "../../../components/managers/PermissionManager";

// Dit is om de employees op te halen via de API
const getEmployeeById = async (id) => {
    try {
        const res = await apiCall(`employee/getbyid/${id}`, "POST");
        return res;
    } catch (error) {
        throw new Error(error.message);
    }
};

// Dit is om de employee op te slaan via de apiCall, API call
const saveEmployeeChanges = async (employee) => {
    const { Roles, ...employeeWithoutRoles } = employee;
    try {
        await apiCall(`employee/update`, "POST", employeeWithoutRoles, {}, true);
        return true;
    } catch (error) {
        throw new Error(error.message);
    }
};

//Dit is om de employee te verwijderen via de API
const deleteEmployee = async (employee) => {
    try {
        const res = await apiCall(`employee/delete`, "POST", employee, {}, true);
        if (res) {
            return true;
        }
        return false;
    } catch (error) {
        throw new Error(error.message);
    }
};

const EmployeeDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [employee, setEmployee] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tempEmployee, setTempEmployee] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [key, setKey] = useState("general");
    const [hideFinished, setHideFinished] = useState(true);
    const [UserRoles, setUserRoles] = useState([]);
    const [FunctionListAPI, setFunctionListAPI] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [modifiedFields, setModifiedFields] = useState({});
    const { user } = useAuth();
    const [currentPermissionsValue, setCurrentPermissionsValue] = useState(null);
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        special: false,
    });
    const [isTouched, setIsTouched] = useState(false);

    // Dit is om de userroles en functionlist op te halen
    useEffect(() => {
        (async () => {
            setUserRoles(await apiCall("metadata/userroles", "POST", {}));
            setFunctionListAPI(await apiCall("metadata/functionlist", "POST", {}));
        })();
    }, []);

    // Dit is om de projects op te halen via de API
    const bulkProjectsByIds = async (projectIds) => {
        try {
            const res = await apiCall("bulk/get/project", "POST", { IDs: projectIds });
            return res;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    // Dit is om de companies op te halen via de API
    const bulkCompanyByIds = async (companyIds) => {
        try {
            const res = await apiCall("bulk/get/company", "POST", { IDs: companyIds });
            return res;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    //Dit is om de employees op te halen via de API dit doe ik voor de badges waarbij je kan zien hoeveel medewerkers er in een company/project zitten
    const bulkEmployeesByIds = async (employeeIds) => {
        try {
            const res = await apiCall("bulk/get/employee", "POST", { IDs: employeeIds });
            return res;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    // Dit is om de afgeronde projecten te verbergen
    const toggleHideFinished = () => {
        setHideFinished(!hideFinished);
    };

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const employeeData = await getEmployeeById(id);
                setEmployee(employeeData);
                setTempEmployee(employeeData);
                setLoading(false);

                if (employeeData) {
                    if (employeeData.Projects) {
                        const projectIds = employeeData.Projects.filter(proj => typeof proj === 'string');
                        if (projectIds.length > 0) {
                            const projectResponse = await bulkProjectsByIds(projectIds);
                            setProjects(projectResponse);
                        }
                    }

                    if (employeeData.Company) {
                        const companyIds = employeeData.Company.filter(comp => typeof comp === 'string');
                        if (companyIds.length > 0) {
                            const companyResponse = await bulkCompanyByIds(companyIds);
                            setCompanies(companyResponse);

                            const employeeIds = companyResponse.flatMap(company => company.Employees);
                            if (employeeIds.length > 0) {
                                const employeeResponse = await bulkEmployeesByIds(employeeIds);

                                if (employeeResponse && Array.isArray(employeeResponse)) {
                                    setEmployees(employeeResponse);
                                } else {
                                    console.error("Invalid employee response:", employeeResponse);
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (id) {
            fetchEmployee();
        }
    }, [id]);

    // Dit is om de permissions value te berekenen
    useEffect(() => {
        if (UserRoles && tempEmployee && tempEmployee.Roles) {
            const permissionsValue = calculatePermissionsValue(UserRoles, tempEmployee.Roles);
            setCurrentPermissionsValue(permissionsValue);
        }
    }, [UserRoles, tempEmployee]);

    // Dit is om de totale uren te berekenen
    const calculateTotalHours = () => {
        if (tempEmployee) {
            const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = tempEmployee;
            const days = [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday];
            let total = 0;

            for (let day of days) {
                if (day != null) {
                    let hours = parseFloat(day);
                    if (!isNaN(hours)) {
                        total += hours;
                    }
                }
            }

            const totalMinutes = Math.floor(total * 60);
            const hours = Math.floor(totalMinutes / 60);

            const formattedTotalHours = `${hours}`;
            setTotalHours(formattedTotalHours);
            setTempEmployee(prevState => ({
                ...prevState,
                totalHours: formattedTotalHours
            }));
        }
    };

    useEffect(() => {
        calculateTotalHours();
    }, [tempEmployee]);

    // Dit is om de employee op te slaan
    const handleSave = async () => {
        try {
            const permissionsValue = calculatePermissionsValue(UserRoles, tempEmployee.Roles);
            const success = await saveEmployeeChanges({
                ...tempEmployee,
                PermissionsValue: permissionsValue.toString(),
            });
            if (success) {
                setEmployee(tempEmployee);
                setIsEditing(false);
            }
        } catch (error) {
            setError(error);
            displayToast("Employee kan niet worden opgeslagen", "error");
        }
    };

    // Dit is om in de editmode te gaan
    const handleEdit = () => {
        setIsEditing(true);
        displayToast("U bent nu in bewerkingsmodus", "info");
    };

    // Dit is om de bewerking te annuleren	
    const handleCancel = () => {
        setTempEmployee(employee);
        setIsEditing(false);
        displayToast("U bent niet meer in bewerkingsmodus", "info");
    };

    // Dit is om de input te veranderen van de rollen, ook kan deze functie gebruikt worden voor andere inputvelden zoals de accountstatus
    const handleChange = (e) => {
        const { id, value, checked, type } = e.target;
    
        // Update tempEmployee state
        setTempEmployee((prevState) => ({
            ...prevState,
            [id]: type === 'checkbox' ? checked : value
        }));
    
        // Track modified fields
        setModifiedFields((prevState) => ({
            ...prevState,
            [id]: type === 'checkbox' ? checked : value
        }));
    };
    

    // Dit is om de employee te verwijderen
    const handleDeleteEmployee = async () => {
        const confirmDelete = window.confirm("Weet u zeker dat u deze medewerker wilt verwijderen? Deze actie kan niet worden teruggedraaid");
        if (confirmDelete) {
            try {
                const success = await deleteEmployee(tempEmployee);
                if (success) {
                    navigate(-1);
                }
            } catch (error) {
                console.error(error);
                displayToast("Employee kan niet worden verwijderd", "error");
            }
        } else {
            displayToast("Employee is niet verwijderd", "info");
        }
    };

    if (loading) {
        return <div>Laden...</div>;
    }

    if (!employee) {
        return <div>Error: ophalen van medewerker mislukt</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Dit is om de wachtwoordvereisten te valideren
    const validatePassword = (value) => {
        setPasswordRequirements({
            length: value.length >= 12,
            maxlength: value.length <= 256,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            digit: /\d/.test(value),
            special: /[!@#$%&*]/.test(value),
            allowedChars: /^[A-Za-z\d!@#$%&*]*$/.test(value),
        });
    };

    // Dit is om de dagen en totaaluren te resetten
    const handleClear = () => {
        setTempEmployee(prevState => ({
            ...prevState,
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0,
            totalHours: 0
        }));
        displayToast("Dagen en totaaluren zijn gereset", "info");
    };

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col sm={6}>
                        <h3>
                            {isEditing ? `Bewerken - ${employee.Firstname} ${employee.Lastname}` : `Details - ${employee.Firstname} ${employee.Lastname}`} <FunctionBadge functionName={employee.Function} functionList={FunctionListAPI} />
                        </h3>
                    </Col>
                    <Col sm={6} className="text-end">
                        <Button variant="danger" onClick={() => navigate(-1)}><i className="fa fa-arrow-left" /> Terug</Button>
                    </Col>
                </Row>
            </Card.Header>

            <Tabs onSelect={setKey} id="controlled-tab-example">
                <Tab eventKey="details" title="Details">
                    <Form>
                        <Row>
                            <Col sm={6}>
                                <InputGroup className="mb-3 p-1 mt-3">
                                    <InputGroup.Text className="inputgroup-text">People ID</InputGroup.Text>
                                    <Form.Control type="text" id="id" value={tempEmployee._id} disabled onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Voornaam</InputGroup.Text>
                                    <Form.Control type="text" id="Firstname" value={tempEmployee.Firstname} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Achternaam</InputGroup.Text>
                                    <Form.Control type="text" id="Lastname" value={tempEmployee.Lastname} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Gebruikersnaam</InputGroup.Text>
                                    <Form.Control type="text" id="Username" value={tempEmployee.Username} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Email</InputGroup.Text>
                                    <Form.Control type="text" id="Email" value={tempEmployee.Email} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Telefoon</InputGroup.Text>
                                    <Form.Control type="text" id="Phone" value={tempEmployee.Phone} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Account Status</InputGroup.Text>
                                    <InputGroup.Checkbox
                                        id="AccountStatus"
                                        checked={tempEmployee.AccountStatus}
                                        disabled={!isEditing}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Col>
                            <Col sm={6}>
                                <InputGroup className="mb-3 mt-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Adres</InputGroup.Text>
                                    <Form.Control type="text" id="Address" value={tempEmployee.Address} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Adres2</InputGroup.Text>
                                    <Form.Control type="text" id="Address2" value={tempEmployee.Address2} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Postcode</InputGroup.Text>
                                    <Form.Control type="text" id="Postalcode" value={tempEmployee.PostalCode} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Stad</InputGroup.Text>
                                    <Form.Control type="text" id="City" value={tempEmployee.City} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Land</InputGroup.Text>
                                    <Form.Control type="text" id="Country" value={tempEmployee.Country} disabled={!isEditing} onChange={handleChange} />
                                </InputGroup>
                                <InputGroup className="mb-3 p-1">
                                    <InputGroup.Text className="inputgroup-text">Wachtwoord</InputGroup.Text>
                                    <OverlayTrigger
                                        placement="left"
                                        show={isTouched}
                                        overlay={
                                            <Tooltip>
                                                <div id="password-requirements">
                                                    <p className={passwordRequirements.length ? 'text-success' : 'text-danger'}>Minimaal 12 karakters en maximaal 256 karakters</p>
                                                    <p className={passwordRequirements.lowercase ? 'text-success' : 'text-danger'}>1 kleine letter</p>
                                                    <p className={passwordRequirements.uppercase ? 'text-success' : 'text-danger'}>1 hoofdletter</p>
                                                    <p className={passwordRequirements.digit ? 'text-success' : 'text-danger'}>1 nummer</p>
                                                    <p className={passwordRequirements.special ? 'text-success' : 'text-danger'}>1 speciale karakter</p>
                                                    <p className={passwordRequirements.allowedChars ? 'text-success' : 'text-danger'}>Alleen letters, nummers, en !@#$%&* zijn toegestaan</p>
                                                </div>
                                            </Tooltip>
                                        }
                                    >
                                        <Form.Control
                                            type="password"
                                            id="Password"
                                            value={tempEmployee.Password}
                                            disabled={!isEditing}
                                            onFocus={() => setIsTouched(true)}
                                            onBlur={() => setIsTouched(false)}
                                            onChange={(e) => {
                                                handleChange(e);
                                                validatePassword(e.target.value);
                                            }}
                                        />
                                    </OverlayTrigger>
                                </InputGroup>
                                {(user.Roles.includes("Admin")) && (
                                    <>
                                        <InputGroup className="mb-3 mt-3 p-1">
                                            <InputGroup.Text className="inputgroup-text">Functie</InputGroup.Text>
                                            <Form.Control
                                                as="select"
                                                id="Function"
                                                name="Function"
                                                disabled={!isEditing}
                                                value={tempEmployee.Function}
                                                onChange={handleChange}
                                            >
                                                {FunctionListAPI.map((func, index) => (
                                                    <option key={index} style={{ color: func.color || "#fff" }} value={func.name}>
                                                        {func.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </InputGroup>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Tab>
                <Tab title="Projecten" eventKey="projects">
                    <Row className="p-3">
                        <Col md={12}>
                            <Card>
                                <Card.Header>
                                    <Col>
                                        <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '2px', marginTop: '18px', marginBottom: '25px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                <span>Projects Enlisted</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                                {projects.length > 0 && (
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={hideFinished}
                                                        onChange={toggleHideFinished}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                )}
                                                {projects.length > 0 && (
                                                    <span style={{ fontSize: '0.7em' }}>Hide finished projects</span>
                                                )}
                                            </div>
                                        </h5>
                                    </Col>
                                </Card.Header>
                                <Col md="12">
                                    <div>
                                        {projects.length === 0 ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                                                <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                <h6 style={{ marginTop: '8px' }}><em>This employee is not enlisted in any projects</em></h6>
                                                <FaExternalLinkAlt style={{ cursor: 'pointer', fontSize: '0.9em', marginBottom: '20px', marginLeft: '8px' }} onClick={() => navigate(`/projects`)}></FaExternalLinkAlt>
                                            </div>
                                        ) : (
                                            <ListGroup id="projects">
                                                <Row className="p-3">
                                                    {projects
                                                        .filter(project => !hideFinished || project.Status !== 'Finished')
                                                        .map((project, index) => (
                                                            <Col md={6} key={index}>
                                                                <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <div>
                                                                        {project.Code} {' '} {project.Description} {' '} {StatusBadge(project.Status)}
                                                                    </div>

                                                                    <div style={{ marginLeft: 'auto' }}>

                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    {project.Employees.length > 0
                                                                                        ? `Medewerkers: ${project.Employees.length}`
                                                                                        : 'Nog geen medewerkers'}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Badge bg="primary" text="dark" pill style={{ marginRight: '10px' }}>{project.Employees.length > 0 ? project.Employees.length : '0'}</Badge>
                                                                        </OverlayTrigger>
                                                                        <FaInfoCircle
                                                                            onClick={() => navigate(`/projects/details/${project._id}`)}
                                                                            style={{ cursor: 'pointer', fontSize: '1em', color: 'gray', marginRight: '13px' }}
                                                                        />
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>

                                            </ListGroup>
                                        )}
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
                <Tab title="Organisaties" eventKey="organisations">
                    <Row className="p-3">
                        <Col md={12}>
                            <Card>
                                <Card.Header>
                                    <Col>
                                        <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: '2px', marginTop: '18px', marginBottom: '25px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                                <span>Organisaties</span>
                                            </div>
                                        </h5>
                                    </Col>
                                </Card.Header>
                                <Col md="12">
                                    <div>
                                        {companies.length === 0 ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color: '#a1a1a1' }}>
                                                <FaInfoCircle style={{ fontSize: '1.5em', marginRight: '10px' }} />
                                                <h6 style={{ marginTop: '8px' }}><em>Organisaties</em></h6>
                                            </div>
                                        ) : (
                                            <ListGroup id="companies">
                                                <Row className="p-3">
                                                    {companies.map((company, index) => (
                                                        <Col md={6} key={index}>
                                                            <ListGroup.Item style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div>
                                                                    {company.Name}
                                                                </div>
                                                                <div style={{ marginLeft: 'auto' }}>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {company.Employees.length > 0
                                                                                    ? `Employees: ${company.Employees.length}`
                                                                                    : 'Nog geen employees'}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Badge bg="primary" text="dark" pill style={{ marginRight: '10px' }}>{company.Employees.length > 0 ? company.Employees.length : '0'}</Badge>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {company.Projects.length > 0
                                                                                    ? `Projects: ${company.Projects.length}`
                                                                                    : 'Nog geen Projects'}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Badge bg="warning" text="dark" pill>{company.Projects.length > 0 ? company.Projects.length : '0'}</Badge>
                                                                    </OverlayTrigger>
                                                                    <FaInfoCircle
                                                                        onClick={() => navigate(`/companies/details/${company._id}`)}
                                                                        style={{ cursor: 'pointer', fontSize: '1em', color: 'gray', marginRight: '13px', marginLeft: '10px' }}
                                                                    />
                                                                </div>
                                                            </ListGroup.Item>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </ListGroup>
                                        )}
                                    </div>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Tab>
                <Tab title="Rollen" eventKey="roles">
                    <Container>
                        <Row className="justify-content-center mt-3">
                            <Col md={1}>
                                <h5>Rollen</h5>
                            </Col>
                        </Row>

                        <Row className="m-5">
                            {UserRoles.map((role, index) => (
                                <Col md={4} className="mb-3" key={index}>
                                    <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                            id={`role_${index}`}
                                            value={role}
                                            checked={tempEmployee.Roles.includes(role)}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                        <label style={{ lineHeight: '24px', fontWeight: 600 }}>{role}</label>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        {currentPermissionsValue && (
                            <Row className="justify-content-center">
                                <Col md={2}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Rol value</Tooltip>}
                                    >
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                style={{ textAlign: 'center' }}
                                                type="text"
                                                value={currentPermissionsValue !== null ? currentPermissionsValue.toString() : ''}
                                                disabled
                                            />
                                        </InputGroup>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Tab>
                <Tab title="Werkweek" eventKey="werkweek">
                    <Container>
                        <Row className="justify-content-center align-items-center mt-3">
                            <Col md="auto">
                                <h6>Werkweek</h6>
                            </Col>

                        </Row>
                        <Row className="justify-content-center align-items-center mt-3">
                            <Col md="auto">
                                <span style={{ marginBottom: '10px' }} className="text-muted">Elke decimaal achter de punt is 6 minuten</span>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col md={3}>
                                <Row className=" align-items-center">

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip">Wis alle velden</Tooltip>}
                                    >
                                        <Button style={{ marginBottom: '10px' }} variant="danger" onClick={handleClear} disabled={!isEditing}>
                                            Wissen
                                        </Button>
                                    </OverlayTrigger>

                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Maandag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Monday"
                                            value={tempEmployee.Monday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Dinsdag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Tuesday"
                                            value={tempEmployee.Tuesday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Woensdag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Wednesday"
                                            value={tempEmployee.Wednesday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Donderdag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Thursday"
                                            value={tempEmployee.Thursday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Vrijdag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Friday"
                                            value={tempEmployee.Friday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Zaterdag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Saturday"
                                            value={tempEmployee.Saturday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3 p-1">
                                        <InputGroup.Text className="inputgroup-text">Zondag</InputGroup.Text>
                                        <Form.Control
                                            min="0"
                                            max="24"
                                            step="0.1"
                                            type="number"
                                            id="Sunday"
                                            value={tempEmployee.Sunday}
                                            disabled={!isEditing}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </Row>
                                <Row className="justify-content-center mt-3">
                                    <Col md={12}>
                                        <InputGroup className="mb-3 p-1">
                                            <InputGroup.Text className="inputgroup-text">Totale uren:</InputGroup.Text>
                                            <Form.Control type="text" value={tempEmployee.totalHours} onChange={handleChange} readOnly />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Tab>

            </Tabs>
            <Card.Footer>
                {isEditing ? (
                    <Row>
                        <Col>
                            <Button variant="primary" className="m-1" onClick={handleSave}><i className="fa fa-save" /> Opslaan</Button>
                            <Button variant="danger" className="m-1" onClick={handleCancel}><i className="fa fa-cancel" /> Annuleren</Button>
                        </Col>
                        <Col className="text-end">
                            <Button variant="outline-danger" onClick={handleDeleteEmployee}><i className="fa fa-trash" /> Verwijderen</Button>
                        </Col>
                    </Row>
                ) :
                    <Button variant="primary" className="m-1" onClick={handleEdit}><i className="fa fa-pen" /> Bewerken</Button>
                }
            </Card.Footer>
        </Card>
    );
};

export default EmployeeDetails;