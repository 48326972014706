import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Nav,
    ProgressBar,
    Spinner,
    Tab,
    Row,
    Col,
    Container,
    Form,
} from "react-bootstrap";
import apiCall from "../utils/apiCall";
import {BackButton, Element, Group} from "./ChecklistUtils";
import ChecklistTable from "./ChecklistTable";
// import { usePagination } from "../managers/PaginationManager";
import { useAuth } from "../../context/AuthContext";
import { displayToast } from "../managers/ToastManager";
import {GroupMenu, ElementMenu} from "./MenuManager";

// Hoofdcomponent van de pagina
export default function CheckList(id) {
    // State hooks
    const [elements, setElements] = useState([]);
    const { user } = useAuth();

    // Query hooks
    const [data, setData] = useState(null);
    const [error] = useState(null);
    const [groups, setGroups] = useState([]);
    const [isError] = useState(null);

    // Element hooks
    const [elementActiveStep, setElementActiveStep] = useState(-1);
    const [elementActiveStepIndex, setElementActiveStepIndex] = useState(-1);

    // Answer hooks
    const [answers, setAnswers] = useState({});

    // Group hooks
    const [groupQuestions, setGroupQuestions] = useState([]);
    const [groupActiveStep, setGroupActiveStep] = useState(null);

    // Parent question & sub question hooks
    const [questions, setQuestions] = useState([]);
    const [parentQuestionValue, setParentQuestionValue] = useState({});
    const [projectCode, setProjectCode] = useState("");
    const tbodyRef = useRef(null);
    const [rowCount, setRowCount] = useState(0);
    const [totalAnswers, setTotalAnswers] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [totalProgress, setTotalProgress] = useState(0);
    const [checklistEdited, setChecklistEdited] = useState(false);

    const [answeredGroupQuestions, setAnsweredGroupQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState(0);
    const [progress, setProgress] = useState(0);
    const [groupQuestionIDs, setGroupQuestionIDs] = useState([]);
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    // Table Reference
    const useTableRef = useRef();
    let initialAnswers = {};


    // Zet de WebSocket connectie op
    const connectWebSocket = useCallback(() => {

        if (socketRef.current) {
            socketRef.current.close();
        }

        socketRef.current = new WebSocket(`${process.env.REACT_APP_API_URL_WEBSOCKET}${id}`);

        socketRef.current.onopen = () => {
            console.log("WebSocket connectie geopend");
            displayToast("Live updates zijn ingeschakeld", "info");
            setIsConnected(true);
        };

        socketRef.current.onmessage = async (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log("Bericht ontvangen van server:", message);

                if (message.type === 'update') {
                    await getData();
                } else if (message.type === 'pong') {
                    console.log("Pong ontvangen van server");
                } else {
                    console.error("Onbekend bericht ontvangen van server:", message);
                }
            } catch (error) {
                console.error("Error bij het verwerken van bericht van server:", error);
            }
        };

        socketRef.current.onclose = () => {
            console.log("WebSocket connectie gesloten");
            displayToast("Live updates zijn uitgeschakeld", "info");
            setIsConnected(false);
        };

        socketRef.current.onerror = (error) => {
            console.error("WebSocket connectie error", error);
            displayToast("Er is een fout opgetreden bij het verbinden met de server", "error");
            setIsConnected(false);
        };

        const pingInterval = setInterval(() => {
            if (socketRef.current.readyState === WebSocket.OPEN) {
                console.log("Ping versturen naar server");
                socketRef.current.send(JSON.stringify({ type: 'ping' }));
            }
        }, 15000);

        return () => {
            clearInterval(pingInterval);
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [id]);

    // Haal de data op met de WebSocket connectie
    useEffect(() => {
        const cleanupWebSocket = connectWebSocket();
        getData().then(() => {
            console.log("Data is geladen");
        }).catch((error) => {
            console.error("Error bij het ophalen van data:", error);
        });
        return () => {
            cleanupWebSocket();
        };
    }, [id, connectWebSocket]);


    // Functie om de data op te halen van de server
    const getData = async() => {
        const project = await apiCall(`project/getbyid/${id}`, 'POST');
        const response = await apiCall(`checklist/getbycode/${project.Code}`, 'POST')

        // Check of de response niet undefined is
        if (response !== undefined) {
            setGroups(response.Groups);
            setProjectCode(response.Code);
            setData(response.Questions);
            setTotalProgress(response.Progress);

            // Check of de status van het project "Offerte" is en filter de vragen
            if (project.Status === "Offerte") {
                FilterOfferteQuestions(response);
            } else {
                // Filter de vragen op basis van de rol van de gebruiker
                FilterQuestionsOffRoll(response);
            }
        }
    };

    function FilterOfferteQuestions(props) {
        const filteredData = Object.keys(props.Questions).reduce((acc, group) => {
            acc[group] = Object.values(props.Questions[group])
                .filter(question =>
                    question.References.Quotation === true &&
                    (user.Roles.includes(question.Role) || user.Roles.includes("User"))
                );

            return acc;
        }, {});
        setData(filteredData);
    }
    function FilterQuestionsOffRoll(props) {
        const filteredData = Object.keys(props.Questions).reduce((acc, group) => {
            acc[group] = Object.values(props.Questions[group])
                .filter(question =>
                    user.Roles.includes(question.Role) || user.Roles.includes("User")
                );

            return acc;
        }, {});
        // Zet de gefilterde data in de state
        setData(filteredData);
    }
    function CalculateTotalProgression() {
        setGroupQuestionIDs(groupQuestions.map((question) => question.QuestionID));
        setAnsweredGroupQuestions(Object.keys(answers).filter((answer) => groupQuestionIDs.includes(parseInt(answer))));
        setAnsweredQuestions(Object.keys(answers).length);
        setProgress(Math.ceil((answeredQuestions / rowCount) * 100 || 0));
        let totalQuestionCount = 0;
        setTotalAnswers(Object.values(data).flatMap((question) => question).filter((question) => question.Answer).length);
        Object.values(data).flatMap((question) => question).forEach((question) => {
            if ((question.isActive === true || question.isActive === "true") && question.PageTree === 1) {
                totalQuestionCount++;

                if (question.Answer === "Ja" && question.SubQuestions) {
                    totalQuestionCount += question.SubQuestions.length;
                }
            }
        });
        setTotalQuestions(totalQuestionCount);
        setTotalProgress(Math.ceil((totalAnswers / totalQuestions * 100) || 0));
    }
    function CalculateElementProgression() {
        setRowCount(tbodyRef.current.getElementsByTagName("tr").length);
        setAnsweredQuestions(Object.keys(answers).filter((answer) => groupQuestionIDs.includes(parseInt(answer))).length);
        setProgress(Math.ceil((answeredQuestions / rowCount) * 100 || 0))
    }
    function InitializeAnswers() {
        for (let group in data)
            data[group].map((question) => {
                if (question.Answer) {
                    return initialAnswers[question.QuestionID] = question.Answer;
                }
                else if (question.DefaultAnswer) {
                    return initialAnswers[question.QuestionID] = question.DefaultAnswer;
                } else return null;
            });
        setAnswers(initialAnswers);
    }
    // const { currentItems, AboveTable, BelowTable } = usePagination(groupQuestions);

    /**
     *
     * @param groupIndex
     * @param group
     * @param dataGroup
     * @returns {Promise<void>}
     * Functie om te klikken op een groep
     */
    const handleClickGroup = async (groupIndex, group, dataGroup) => {
        const transformedGroup = group.toLowerCase().replace(/\s/g, '');
        const groupData = dataGroup.find(item => item === transformedGroup);
        setElementActiveStep("");
        setElementActiveStepIndex(-1);

        if (!groupData) {
            console.error(`Groep met de naam ${group} is niet gevonden`);
            return;
        }

        setGroupActiveStep(groupIndex);
        setGroupQuestions([]);

        const newElements = new Set(
            data[groupData].map((question) => question.Element)
        );

        setQuestions(data[groupData]);
        setElements([...newElements]);
    };

    /**
     *
     * @param element
     * @param elementIndex
     * Functie om te klikken op een element
     */
    const handleClickElement = (element, elementIndex) => {
        setElementActiveStep(element);
        setElementActiveStepIndex(elementIndex);

        const filteredQuestions = questions.filter(
            (question) => question.Element === element
        );

        const activefilteredQuestions = filteredQuestions.filter(
            (question) => question.isActive === true || question.isActive === "true"
        );

        let initialParentQuestions = {};
        activefilteredQuestions.forEach(question => {
            if (question.Answer === "Ja" && question.SubQuestions) {
                initialParentQuestions[question.QuestionID] = question.Answer;
            }
            else if (question.Answer === "Nee" && question.SubQuestions) {
                question.SubQuestions.forEach(subQuestionId => {
                    delete initialParentQuestions[subQuestionId];
                });
            }
        });
        setParentQuestionValue(initialParentQuestions);
        setGroupQuestions(activefilteredQuestions);
    };

    /**
     *
     * @param e
     * Functie om naar de volgende onderdeel te gaan
     */
    const handleNext = (e) => {
        e.preventDefault();
        if (elementActiveStepIndex < elements.length - 1) {
            let newElementActiveStepIndex = elementActiveStepIndex + 1;
            setElementActiveStepIndex(newElementActiveStepIndex);
            setElementActiveStep(elements[newElementActiveStepIndex]);
            handleClickElement(
                elements[newElementActiveStepIndex],
                newElementActiveStepIndex,
                groups[groupActiveStep]
            );
        }
    };

    /**
     *
      * @param e
     * Functie om naar de vorige onderdeel te gaan
     */
    const handlePrevious = (e) => {
        e.preventDefault();
        if (elementActiveStepIndex > 0) {
            let newElementActiveStepIndex = elementActiveStepIndex - 1;
            setElementActiveStepIndex(newElementActiveStepIndex);
            setElementActiveStep(elements[newElementActiveStepIndex]);
            handleClickElement(
                elements[newElementActiveStepIndex],
                newElementActiveStepIndex,
                groups[groupActiveStep]
            );
        }
    };

    /**
     *
     * @param e
     * @param question
     * @returns {Promise<void>}
     * Functie om de input van een vraag te verwerken
     */
    const handleInputChange = async (e, question) => {
        // Check of de checklist is bewerkt
        if (checklistEdited === false) setChecklistEdited(true);
        let value;
        // Check of de input een checkbox is
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else {
            value = e.target.value === "on"
                ? true
                : e.target.value === "off"
                    ? false
                    : e.target.value;
        }
        // Update de antwoorden in de state
        setAnswers((prevAnswers) => {
            if (value === "Selecteer een antwoord" || value === "") {
                const newAnswers = { ...prevAnswers };
                delete newAnswers[question.QuestionID];
                return newAnswers;
            } else if (question.SubQuestions && value !== "Ja") {
                const newAnswers = { ...prevAnswers, [question.QuestionID]: value };
                question.SubQuestions.forEach(subQuestionId => {
                    delete newAnswers[subQuestionId];
                });
                return newAnswers;
            } else {
                return {
                    ...prevAnswers,
                    [question.QuestionID]: value,
                };
            }
        });

        // Update de hoofdvraag in de state
        setParentQuestionValue((prevValues) => ({
            ...prevValues,
            [question.QuestionID]: value
        }));

        // Update de antwoorden in de database
        await apiCall('checklist/update_answer', "POST", {
            websocketRef: id,
            id: projectCode,
            group: groups[groupActiveStep].toLowerCase().replace(/\s/g, ''),
            questionID: question.id,
            answer: value,
            required_role: question.Role,
            progress: totalProgress
        });
    };
// Functie om de totale progressie te berekenen
    useEffect(() => {
        data && CalculateTotalProgression();
    }, [rowCount, data, answers]);

    // Functie om de antwoorden in te stellen
    useEffect(() => {
        InitializeAnswers();
    }, [data]);
    // Functie om de progressie te berekenen
    useEffect(() => {
        tbodyRef?.current && CalculateElementProgression();
    }, [tbodyRef.current, answers, elementActiveStep]);

    /**
     *
     * @param _stepsList
     * @param group
     * @param _questions
     * @param isLoading
     * @param error
     * @returns {false|React.JSX.Element}
     * Functie om de vragen te renderen
     */
    const RenderQuestions = ({ group, _questions, error }) => {
        const sortedQuestions = _questions.sort((a, b) => Number(a.QuestionID) - Number(b.QuestionID));
        return (
            groupActiveStep !== null && (
                <div className="columns-container">
                    <hr style={{ border: "transparent" }} />
                    <Row>
                        <Col className="col-12 col-md-9">
                            <Container className="cl-question-container">{/* <QuestionTable className="cl-question-container"> */}
                                <h1>{elementActiveStep ? elementActiveStep : group}</h1>
                                <ProgressBar className="progressbar mb-3" now={progress} label={`${progress}% `} />
                                <Form
                                    id={`form - ${groupActiveStep} -${elementActiveStep
                                        .toString()
                                        .replace(/\s/g, "")
                                        } `}
                                    key={elementActiveStep}
                                >
                                    {/*{AboveTable}*/}
                                    <ChecklistTable
                                        useTableRef={useTableRef}
                                        tbodyRef={tbodyRef}
                                        elements={elements}
                                        elementActiveStep={elementActiveStep}
                                        isError={isError}
                                        error={error}
                                        groupQuestions={groupQuestions}
                                        sortedQuestions={sortedQuestions}
                                        _questions={_questions}
                                        parentQuestionValue={parentQuestionValue}
                                        answers={answers}
                                        handleInputChange={handleInputChange} />
                                    {/*{BelowTable}*/}
                                    <div className="btn-footer">
                                        <button
                                            className="btn btn-danger"
                                            onClick={handlePrevious}
                                            disabled={
                                                elementActiveStepIndex < 0 ||
                                                elementActiveStepIndex === 0
                                            }
                                        >
                                            <i className="fa fa-arrow-left" /> Vorige onderdeel
                                        </button>
                                        <button
                                            className="btn btn-success"
                                            onClick={handleNext}
                                            disabled={elementActiveStepIndex === elements.length - 1}
                                        >
                                            Volgende onderdeel <i className="fa fa-arrow-right" />
                                        </button>
                                    </div>
                                </Form>
                            </Container>{/* </QuestionTable> */}
                        </Col>
                    </Row>
                </div>
            )
        );
    };

    return (
            <div className="cl-main-container">
                {data ? (
                    <>
                        <Row>
                            <Col sm={4} className="cl-title">
                                <h3>
                                    Checklist - Project: {projectCode}
                                </h3>
                            </Col>
                            <Col sm={8} className="text-end ">
                                <BackButton />
                            </Col>
                        </Row>
                        <ProgressBar className="progressbar m-1" now={totalProgress}
                            label={`${totalProgress}% `} />

                        <Tab.Container id="step-navigation">
                            <GroupMenu groups={groups} data={data} groupActiveStep={groupActiveStep} handleClickGroup={handleClickGroup} user={user} />
                            <ElementMenu elements={elements} groupActiveStep={groupActiveStep} elementActiveStep={elementActiveStep} handleClickElement={handleClickElement} groups={groups} RenderQuestions={RenderQuestions} groupQuestions={groupQuestions} error={error} />
                        </Tab.Container>
                    </>
                ) : (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Laden...</span>
                        </Spinner>
                    </div>
                )
                }
            </div>
    );
}