import {Element, Group} from "./ChecklistUtils";
import {Nav, Tab} from "react-bootstrap";
import React from "react";


export const ElementMenu = ({
                         elements,
                         groupActiveStep,
                         elementActiveStep,
                         handleClickElement,
                         groups,
                         RenderQuestions,
                         groupQuestions,
                         error
                     }) => {

    return(
<Tab.Content>
    <div className="content-container">
        <div className="cl-column">
            <hr className="hr" />
            {elements.length > 0 && <h3>Onderdelen</h3>}
            {elements.map((element, elementIndex) => (
                <Element
                    element={element}
                    elementIndex={elementIndex}
                    groupActiveStep={groupActiveStep}
                    elementActiveStep={elementActiveStep}
                    handleClickElement={handleClickElement}
                    group={groups[groupActiveStep]}
                />
            ))}
        </div>{/* </ElementColumn> */}
        <RenderQuestions
            group={groups[groupActiveStep]}
            _questions={groupQuestions}
            error={error}
        />
    </div> {/* </ElementMenu.jsx> */}
</Tab.Content>
    )
}

export const GroupMenu = ({groups, user, data, groupActiveStep, handleClickGroup}) => {
    return(
    <Nav>
        {groups.map((group, groupIndex) => (
            user.Roles.includes(group) || user.Roles.includes("User") ?
                <Group group={group}
                       datagroup={Object.keys(data)}
                       groupIndex={groupIndex}
                       groupActiveStep={groupActiveStep}
                       handleClickGroup={handleClickGroup}
                       data={data}
                /> : null
        ))}
    </Nav>
    )

}