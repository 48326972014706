import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import apiCall from '../../components/utils/apiCall';
import { FaInfoCircle } from 'react-icons/fa'
import Bug from "./Bug";
import Feature from "./Feature";
import {displayToast} from "../../components/managers/ToastManager";
import {capitalize} from "@mui/material";


/**
 * README FeedbackPage
 *
 * [feedback].jsx is een React component dat wordt gebruikt om feedback te geven over een bug of een feature.
 * Het bevat een customalert component waar wordt gevraagd of de feedback een bug of een feature is.
 *
 * =====================================================================================================================
 *
 * ChangeCreateChecklistModal:
 * Dit component bevat een formulier om feedback te geven over een bug of een feature.
 * Het formulier bevat verschillende inputvelden die de gebruiker moet invullen.
 *
 *  - Bug:
 *      - Onderwerp
 *      - Reproduceerbaarheid
 *      - Actie
 *      - Resultaat
 *      - Beschrijving
 *  - Feature:
 *      - Onderwerp
 *      - Feature Type
 *      - Feature Impact
 *      - Feature Change
 *          - Alleen zichtbaar als Feature Type = 'existing'
*       - Beschrijving
 *
 * =====================================================================================================================
 *
 * Gebruik:
 * Om deze component te gebruiken, importeer dan de FeedbackPage component in een ander React bestand.
 *
 * Opmerking
 * De FeedbackPage component vereist geen props.
 *
 */

/**
 *
 * @returns {Element}
 * @constructor
 */
const FeedbackPage = () => {
    const {user} = useAuth();
    const {feedback} = useParams();
    const navigate = useNavigate();

    // Functie om feedback te verzenden
    function handleSubmit(data) {
        async function sendFeedback()
        {
            try {
                await apiCall(`feedback/${feedback}`, 'POST', data)
                    .then(displayToast("Feedback is verzonden", "success"))
                    .finally(
                    navigate("/dashboard")
                )
            } catch (error) {
                displayToast('Er is iets misgegaan bij het verzenden van de feedback', 'error', );
            }
        }
        sendFeedback();
    };

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col md={6}>
                        <h3>{capitalize(feedback)} Report</h3>
                    </Col>
                    <Col md={6} className="text-end">
                        <Button variant="danger" style={{ margin: "4px" }} onClick={() => navigate(-1)}><i className="fa fa-arrow-left" /> Terug</Button>
                    </Col>
                </Row>
                <Card.Text>
                    <FaInfoCircle /> Vul alstublieft zoveel mogelijk details in. Dit helpt ons om de feedback beter te begrijpen.
                </Card.Text>
            </Card.Header>
            <Card.Body>
                <Row>
                    <h4>Gebruiker</h4>
                    <Col md={6}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className='inputgroup-text'>Naam</InputGroup.Text>
                            <Form.Control disabled type="text" id="name" name="name" value={user.Fullname} />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className='inputgroup-text'>Functie</InputGroup.Text>
                            <Form.Control disabled type="text" id="name" name="name" value={user.Function} />
                        </InputGroup>
                    </Col>
                </Row>
                <hr />
            {feedback === 'bug' ? <Bug props={user} handleSubmit={handleSubmit}/>
                : feedback === 'feature' && <Feature props={user} handleSubmit={handleSubmit}/>}
            </Card.Body>
        </Card>
    );
};

export default FeedbackPage;
