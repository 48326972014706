import {useRef, useState} from "react";
import autoExpand from "../../components/utils/autoExpand";
import {Button, Col, Form, Row} from "react-bootstrap";

const Feature = ({props, handleSubmit}) => {
    const [formDataFeature, setFormDataFeature] = useState({
        id: props.EmployeeID,
        subject: '',
        feature_type: '',
        feature_impact: '',
        feature_change: '',
        description: ''
    });
    const FormRef = useRef()

    return (
        <Form ref={FormRef} className='was-validated' onSubmit={() => handleSubmit(formDataFeature)}>
            <Row>
                <h4>Feature Aanvraag</h4>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Onderwerp</Form.Label>
                        <Form.Control type="text" id="subject" name="subject" onChange={(e) => setFormDataFeature({
                            ...formDataFeature,
                            subject: e.target.value
                        })} required/>
                    </Form.Group>
                </Col>
                <hr/>
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label> Wat voor soort feature wilt u aanvragen? </Form.Label>
                        <Form.Select id="feature_type" name="feature_type" onChange={(e) => setFormDataFeature({
                            ...formDataFeature,
                            feature_type: e.target.value
                        })} required>
                            <option value="">--- Selecteer een optie ---</option>
                            <option value="new">Nieuwe feature</option>
                            <option value="existing">Verander een bestaande feature</option>
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label> Wat is de impact van deze feature? </Form.Label>
                        <textarea className='form-control' id="feature_impact"
                                  placeholder='Voer minimaal 32 tekens in' name="feature_impact"
                                  minLength={32} maxLength={1024} onInput={(e) => autoExpand(e.target)}
                                  onChange={(e) => setFormDataFeature({
                                      ...formDataFeature,
                                      feature_impact: e.target.value
                                  })} required/>
                    </Form.Group>
                </Col>
                {formDataFeature.feature_type === "existing" ? (
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label> Wat wilt u veranderen aan de feature? </Form.Label>
                            <Form.Control type="text" id="feature_change" name="feature_change"
                                          onChange={(e) => setFormDataFeature({
                                              ...formDataFeature,
                                              feature_change: e.target.value
                                          })} required/>
                        </Form.Group>
                    </Col>
                ) : null}
                <hr/>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label> Kunt u de feature in detail beschrijven? </Form.Label>
                        <textarea className='form-control' id="description" placeholder='Voer minimaal 32 tekens in'
                                  name="description" minLength={32} maxLength={1024}
                                  onInput={(e) => autoExpand(e.target)} onChange={(e) => setFormDataFeature({
                            ...formDataFeature,
                            description: e.target.value
                        })} required/>
                    </Form.Group>
                </Col>
            </Row>
            <Button type="submit" disabled={!FormRef?.current?.checkValidity()}><i
                className='fa fa-paper-plane'/> Versturen</Button>)
        </Form>
    );
}

export default Feature;