import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import apiCall from '../../components/utils/apiCall';

export default function ExtraEmployee() {

    const [employees, setEmployees] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);

    // async useEffect
    useEffect(() => {
        const fetchEmployee = async () => {
            const employees = await apiCall("employee/all", "POST");
            setEmployees(employees);
            if (isUpdated) {
                setIsUpdated(false);
            }
        }
        fetchEmployee();
    }, [isUpdated]);

    const handleConfettiDisable = async (id) => {
        await apiCall(`employee/disableconfetti/${id}`, "POST");
        setIsUpdated(true);
    }

    const handleConfettiEnable = async (id) => {
        await apiCall(`employee/enableconfetti/${id}`, "POST");
        setIsUpdated(true);
    }

    return (
        <Container>
            <Card className="mt-5">
                <Card.Header>
                    <Card.Title as="h5">Funny Tool</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        {employees.map((employee, index) => (
                            <Col key={index} md={4} className="d-flex align-items-center mb-3">
                                <div className='flex-grow-1'>
                                    <h6>{employee.Firstname} {employee.Lastname}</h6>
                                </div>
                                {employee.Confetti ? 
                                    <Button 
                                        size="sm" 
                                        variant="danger"
                                        onClick={() => handleConfettiDisable(employee._id)}
                                    >
                                        Disable Confetti
                                    </Button>
                                    :
                                    <Button 
                                        size="sm" 
                                        variant="success"
                                        onClick={() => handleConfettiEnable(employee._id)}
                                    >
                                        Enable Confetti
                                    </Button>
                                }
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}