import { Badge, Button, Card, CardBody, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { displayToast } from "../../components/managers/ToastManager";
import { StatusList } from '../../components/misc/HardcodedLists';
import apiCall from "../../components/utils/apiCall";
import { FaTrash } from "react-icons/fa";

export default function AddProject() {
  const [Code, setCode] = useState("");
  const [Description, setDescription] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Status, setStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState(new Set());
  const [readOnly, setReadOnly] = useState(false);
  const [searchEndCustomer, setSearchEndCustomer] = useState("");
  const [selectedEndCustomer, setSelectedEndCustomer] = useState(null);
  const [selectedEndCustomerId, setSelectedEndCustomerId] = useState(null);
  const [searchSupplyingCompany, setSearchSupplyingCompany] = useState("");
  const [selectedSupplyingCompany, setSelectedSupplyingCompany] = useState(null);
  const [selectedSupplyingCompanyId, setSelectedSupplyingCompanyId] = useState(null);

  const navigate = useNavigate();

  const handleCompanyDeselect = (company) => {
    const updatedSelectedCompanies = selectedCompanies.filter(selectedCompany => selectedCompany !== company);
    setSelectedCompanies(updatedSelectedCompanies);

    const updatedSelectedCompanyIds = new Set(selectedCompanyIds);
    updatedSelectedCompanyIds.delete(company._id);
    setSelectedCompanyIds(updatedSelectedCompanyIds);

    setReadOnly(updatedSelectedCompanyIds.size > 0);
  };

  const handleEndCustomerDeselect = () => {
    setSelectedEndCustomer(null);
    setSelectedEndCustomerId(null);
  };

  const handleSupplyingCompanyDeselect = () => {
    setSelectedSupplyingCompany(null);
    setSelectedSupplyingCompanyId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    
    const startDateYear = StartDate.split("-")[0];
    const endDateYear = EndDate.split("-")[0];
  
    if (startDateYear.length > 4 || (EndDate && endDateYear.length > 4)) {
      displayToast("Jaar mag maximaal 4 cijfers bevatten", "error");
      return;  
    }
  
    try {
      const selectedCompanyId = Array.from(selectedCompanyIds)[0]?.toString() || "";
      const result = await apiCall("project/create", "POST", {
        Code: Code,
        Description: Description,
        StartDate: StartDate,
        EndDate: EndDate,
        Status: Status,
        CustomerID: selectedCompanyId,
        EndCustomerID: selectedEndCustomerId,
        SupplyingCompanyID: selectedSupplyingCompanyId,
      });
  
      if (result) {
        navigate("/dashboard");
        displayToast("Project is toegevoegd", "success");
      }
    } catch (error) {
      console.error("Error met het toevoegen van project: ", error);
      displayToast("Project toevoegen is mislukt", "error");
    }
  };
  

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const selectedCompany = companies.find((company) => company.Name === inputValue);

    if (selectedCompany && !selectedCompanyIds.has(selectedCompany._id)) {
      setSelectedCompanies([...selectedCompanies, selectedCompany]);
      setSelectedCompanyIds(new Set(selectedCompanyIds).add(selectedCompany._id));
      setSearchInput("");
      setReadOnly(true);
    } else {
      setSearchInput(inputValue);
    }
  };

  const handleEndCustomerChange = (e) => {
    const inputValue = e.target.value;
    const selectedCompany = companies.find((company) => company.Name === inputValue);

    if (selectedCompany) {
      setSelectedEndCustomer(selectedCompany);
      setSelectedEndCustomerId(selectedCompany._id);
      setSearchEndCustomer("");
    } else {
      setSearchEndCustomer(inputValue);
    }
  };

  const handleSupplyingCompanyChange = (e) => {
    const inputValue = e.target.value;
    const selectedCompany = companies.find((company) => company.Name === inputValue);

    if (selectedCompany) {
      setSelectedSupplyingCompany(selectedCompany);
      setSelectedSupplyingCompanyId(selectedCompany._id);
      setSearchSupplyingCompany("");
    } else {
      setSearchSupplyingCompany(inputValue);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projects = await apiCall('project/all', 'POST');
        if (projects.length > 0) {
          const codes = projects.map(project => project.Code);
          const maxCode = Math.max(...codes.map(code => parseInt(code.substring(1))));
          setCode(`P${maxCode + 1}`);
        } else {
          setCode('');
        }
      } catch (error) {
        displayToast("Kon niet de projecten ophalen", "error");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await apiCall("company/all", "POST");
        setCompanies(response);
      } catch (error) {
        console.error("Error met het ophalen van de companies: ", error);
        displayToast("Laden van de companies is mislukt", "error");
      }
    };

    getCompanies();
  }, [])

  const handleReadOnlyInputClick = (fieldValue) => {
    if (fieldValue) {
      displayToast("U kan maar een bedrijf tegelijk selecteren", "info");
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={6}>
              <h4>Project - Create</h4>
            </Col>
            <Col sm={6} className={"text-end"}>
              <Button variant="danger" onClick={() => navigate(-1)}>
                <i className="fa fa-arrow-left" />Back
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Card>
              <Card.Header>Project Information <Badge bg={"danger"}>Required</Badge> </Card.Header>
              <Card.Body>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Code
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text" 
                      value={Code} 
                      onChange={e => setCode(e.target.value)} 
                      placeholder="P0000" 
                      pattern="P\d{4}"
                      title="Code moet beginnen met een 'P' en moet gevolgd worden door 4 nummers"
                      required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Omschrijving
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control 
                      type="text" 
                      value={Description} 
                      onChange={e => setDescription(e.target.value)} 
                      placeholder="Uw project omschrijving" 
                      pattern=".{4,64}"
                      title="Description moet minimaal 4 characters en maximaal 64 characters lang zijn"
                      required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Start Datum
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control 
                    type="date" 
                    value={StartDate} 
                    onChange={e => setStartDate(e.target.value)} 
                    required />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Eind Datum
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control 
                    type="date" 
                    value={EndDate} onChange={e => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Status
                  </Form.Label>
                  <Col sm="10">
                    <Form.Select 
                    value={Status} 
                    onChange={e => setStatus(e.target.value)} 
                    required
                    >
                      <option value="">Select Status</option>
                      {StatusList.map((stat, index) => (
                        <option key={index} value={stat}>
                          {stat}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Leverancier
                  </Form.Label>
                  <Col sm="10">
                    <div className="input-with-icon">
                      {selectedSupplyingCompany && (
                        <div className="selected-customer">
                          <input
                            className="form-control"
                            type="text"
                            value={selectedSupplyingCompany.Name}
                            readOnly
                            onClick={() => handleReadOnlyInputClick(selectedSupplyingCompany.Name)}
                          />
                          <FaTrash
                            className="trash-icon"
                            onClick={handleSupplyingCompanyDeselect}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </div>
                      )}
                      {!selectedSupplyingCompany && (
                        <input
                          className="form-control"
                          list="companyList"
                          type="text"
                          value={searchSupplyingCompany}
                          onChange={handleSupplyingCompanyChange}
                          placeholder="Zoek en selecteer een leverancier"
                          onClick={() => handleReadOnlyInputClick(searchSupplyingCompany)}
                        />
                      )}
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Klant
                  </Form.Label>
                  <Col sm="10">
                    <div className="input-with-icon">
                      {selectedCompanies.length > 0 && (
                        <div className="selected-customer">
                          <input
                            className="form-control"
                            type="text"
                            value={selectedCompanies[0].Name}
                            readOnly
                            onClick={() => handleReadOnlyInputClick(selectedCompanies[0].Name)}
                          />
                          <FaTrash
                            className="trash-icon"
                            onClick={() => handleCompanyDeselect(selectedCompanies[0])}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </div>
                      )}
                      {!readOnly && (
                        <input
                          className="form-control"
                          list="companyList"
                          type="text"
                          value={searchInput}
                          onChange={handleInputChange}
                          placeholder="Zoek en selecteer een klant"
                          onClick={() => handleReadOnlyInputClick(searchInput)}
                        />
                      )}
                    </div>
                    <datalist id="companyList">
                      {companies &&
                        companies.length > 0 &&
                        companies
                          .filter(
                            (company) =>
                              !selectedCompanyIds.has(company._id) &&
                              !selectedCompanies.some(
                                (selected) => selected._id === company._id
                              )
                          )
                          .map((company) => {
                            return <option key={company._id} value={company.Name} />;
                          })}
                    </datalist>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Eind Klant
                  </Form.Label>
                  <Col sm="10">
                    <div className="input-with-icon">
                      {selectedEndCustomer && (
                        <div className="selected-customer">
                          <input
                            className="form-control"
                            type="text"
                            value={selectedEndCustomer.Name}
                            readOnly
                            onClick={() => handleReadOnlyInputClick(selectedEndCustomer.Name)}
                          />
                          <FaTrash
                            className="trash-icon"
                            onClick={handleEndCustomerDeselect}
                            style={{ color: 'red', cursor: 'pointer' }}
                          />
                        </div>
                      )}
                      {!selectedEndCustomer && (
                        <input
                          className="form-control"
                          list="companyList"
                          type="text"
                          value={searchEndCustomer}
                          onChange={handleEndCustomerChange}
                          placeholder="Zoek en selecteer een eind klant"
                          onClick={() => handleReadOnlyInputClick(searchEndCustomer)}
                        />
                      )}
                    </div>
                  </Col>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button type="submit" variant="success">
                  <i className="fa fa-plus" /> Project toevoegen
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
