import { useAuth } from "../../context/AuthContext";
import React, { useState, useEffect } from "react";
import { Card, ListGroup, Form, Button } from "react-bootstrap";
import { FaPlusCircle, FaTrash, FaCopy, FaPaperPlane } from "react-icons/fa";
import apiCall from "../../components/utils/apiCall"; // Adjust the import path as needed

export default function TimesheetHistory() {
    const { user } = useAuth();
    const [employee, setEmployee] = useState(null);
    const [timesheet, setTimesheet] = useState([]);

    useEffect(() => {
        const fetchTimesheetData = async () => {
            try {
                const employeeData = await getEmployeeById(user.EmployeeID);
                setEmployee(employeeData);
                
                // if (employeeData) {
                //     const timesheetData = await getTimesheetData(employeeData.EmployeeID);
                //     setTimesheet(timesheetData);
                // }
            } catch (error) {
                console.error("Error fetching timesheet data:", error);
            }
        };
        fetchTimesheetData();
    }, [user.EmployeeID]);

    const getEmployeeById = async (id) => {
        try {
            const res = await apiCall(`employee/getbyid/${id}`, "POST");
            return res;
        } catch (error) {
            throw new Error(error.message);
        }
    };

    // const getTimesheetData = async (employeeId) => {
    //     try {
    //         const res = await apiCall(`timesheet/getbyemployeeid/${employeeId}`, "POST");
    //         return res;
    //     } catch (error) {
    //         throw new Error(error.message);
    //     }
    // };

    return (
        <Card>
            <Card.Header>
                <h1>History</h1>
            </Card.Header>
            <div>
                <ListGroup variant="flush">
                    <ListGroup.Item variant="secondary" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h5 style={{ margin: 0, marginRight: '20px' }}>Projects</h5>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form>
                    <Form.Control type="text" placeholder="Add Description" />
                    </Form>
                        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <div style={{ marginLeft: '10px'}}>
                    <strong>• (P4991) Test </strong> - <dfn>Generation</dfn>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '70px' }}>
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                    <Form.Group key={day} style={{ marginRight: '5px' }}>
                        <Form.Control
                        type="number"
                        value={[day]}
                        placeholder={day.substring(0, 3)}
                        style={{ height: '40px', padding: '5px', width: '150px' }}
                        min={0}
                        max={24}
                        step="0.1"
                        disabled
                        />
                    </Form.Group>
                    ))}
                    <Button
                    variant="danger"
                    size="sm"
                    style={{ marginLeft: '93px', marginRight: '10px' }}
                    >
                    <FaTrash />
                    </Button>
                    </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Card>
    );
}
