import React from 'react'
import ProjectMetaDataTable from '../../../components/tables/ProjectMetaDataTable'
import { useParams } from 'react-router-dom'


export default function Equipmentlist() {

    const { id } = useParams();

    return (
        <ProjectMetaDataTable id={id} type="data_equipmentlist" />
    )
}